import React from 'react';
function convertArrayOfObjectsToCSV(array, userInfo = {}) {
  let result;
  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(array[0]);

  result = '';
  if (userInfo) {
    result += `Leave report of ${userInfo.name}`;
    result += lineDelimiter;
    result += `Total Leave: ${userInfo.totalLeave}`;
    result += lineDelimiter;
    result += `Available Leave: ${userInfo.availableLeave}`;
    result += lineDelimiter;
    result += `Consumed Leave: ${userInfo.consumedLeave}`;
    result += lineDelimiter;
    result += `Leave Count by Leave Type`;
    if (userInfo.startDate && userInfo.endDate) {
      result += lineDelimiter;
      result += `Date From: ${userInfo.startDate} to ${userInfo.endDate}`;
    }
    result += lineDelimiter;
    result += `Casual Leave: ${userInfo.casualLeaveCount}`;
    result += lineDelimiter;
    result += `Sick Leave: ${userInfo.sickLeaveCount}`;
    if(userInfo.specialLeaveCount) {
      result += lineDelimiter;
      result += `Special Leave: ${userInfo.specialLeaveCount}`;
    }
    result += lineDelimiter;
    result += `Family Leave: ${userInfo.familyLeaveCount}`;
    result += lineDelimiter;
    result += `Holiday Leave: ${userInfo.holidayLeaveCount}`;
    result += lineDelimiter;
    result += `Parenthood Leave: ${userInfo.parenthoodLeaveCount}`;
    result += lineDelimiter;
    result += `Marital Leave: ${userInfo.maritalLeaveCount}`;
    result += lineDelimiter;
    result += `Bonus Leave: ${userInfo.bonusLeaveCount}`;
    result += lineDelimiter;
    result += `Unpaid Leave: ${userInfo.unpaidLeaveCount}`;
    result += lineDelimiter;
  }
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function downloadCSV(array, userInfo = {}) {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(array, userInfo);
  if (csv == null) return;

  const filename = `leave-report-of-${userInfo.name}.csv`;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}

function ExportCsv({ onExport, userInfo }) {
  return (
    <button
      className="btn btn-export"
      onClick={() => downloadCSV(onExport, userInfo)}
    >
      Export
    </button>
  );
}

export default ExportCsv;
