import React, { useMemo, useState } from 'react';
import CardSummary from '../components/CardSummary';
import useFetch from '../hooks/useFetch';
import { Link } from 'react-router-dom';
// data table import
import DataTable from '../components/DataTableBase';
import { FilterComponent } from '../components/FilterComponent';

const TeamManagement = () => {
  // get team data
  const { data: teams } = useFetch('/team');
  // get designation data
  const { data: designations } = useFetch('/des');
  // get all the active teams
  const activeTeams = teams.filter((data) => data.isActive === 'Active');
  // get all the active designation
  const activeDesignations = designations.filter(
    (data) => data.isActive === 'Active'
  );
  // get team length
  const totalTeam = activeTeams?.length;
  // get designation length
  const totalDesignation = activeDesignations?.length;
  // data table column name setup
  const columns = [
    {
      name: 'Designation',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.isActive,
    },
    {
      name: 'Edit',
      selector: (row) => (
        <Link to={`/designation-edit/${row?._id}`} className="btn">
          Edit
        </Link>
      ),
    },
  ];
  // setup filter for search
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = designations.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.status &&
        item.status.toLowerCase().includes(filterText.toLowerCase()))
  );
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  // setup filter for search ends

  return (
    <main>
      <div className="container">
        <div className="headingWithBtn">
          <h1>Team Management</h1>
          <div>
            <Link to={'/team-add'} className="btn btn-primary">
              Add Team
            </Link>
          </div>
        </div>
        <h2>Total {totalTeam} Active Team</h2>

        <div className="infoSummary">
          <div className="gridRow">
            {activeTeams.map((team) => (
              <CardSummary
                key={team._id}
                data={team}
                editLink={`/team-edit/${team?._id}`}
              ></CardSummary>
            ))}
          </div>
        </div>
      </div>

      <div className="designation container">
        <div className="headingWithBtn">
          <h1>Designation</h1>
          <div>
            <Link to={'/designation-add'} className="btn btn-primary">
              Add Designation
            </Link>
          </div>
        </div>
        <h2>Total {totalDesignation} Designation Found</h2>
        <DataTable
          columns={columns}
          data={filteredItems}
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          highlightOnHover
        />
      </div>
    </main>
  );
};

export default TeamManagement;
