import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

// data table import
import DataTable from './DataTableBase';
import { FilterComponent } from './FilterComponent';

function AdminApprovedLeave(props) {
  const columns = [
    {
      name: 'Name',
      selector: (row) => row?.employeeInfo?.name,
      sortable: true,
    },
    {
      name: 'Leave For',
      selector: (row) => row?.count,
    },
    {
      name: 'Start Date',
      selector: (row) => row?.startDate.split('T')[0],
      sortable: true,
    },
    {
      name: 'End Date',
      selector: (row) => row?.endDate.split('T')[0],
      sortable: true,
    },
    {
      name: 'Leave Type',
      selector: (row) => row?.type,
      sortable: true,
    },
    {
      name: 'Edit',
      selector: (row) => (
        <Link to={`/leave-adjust/${row._id}`} state={row} className="btn">
          Adjust
        </Link>
      ),
    },
  ];
  // setup filter for search
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredApprovedLeave = props?.approvedLeave.filter(
    (item) =>
      (item.employeeInfo?.name &&
        item.employeeInfo?.name
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item.type &&
        item.type.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.title &&
        item.title.toLowerCase().includes(filterText.toLowerCase()))
  );
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  // expandable row configuration
  const ExpandedComponent = ({ data }) => (
    <div className="leaveReason">
      <p>{`Reason: ${data?.reason}`}</p>
      {data?.adminNote && <p>{`Admin Note: ${data?.adminNote}`}</p>}
      {(data?.leavePenalty > 0 || data?.leavePenalty < 0) && (
        <p>{`Leave Adjusted by: ${data.leavePenalty} ${data.leavePenalty > 1 ? 'days' : 'day'}`}</p>
      )}
    </div>
  );

  if (props?.approvedLeave.length === 0) {
    return <h2 className="mt-30">No Approved Leave applications</h2>;
  }
  return (
    <div className="pendingLeave">
      <h2>Approved Leave applications</h2>
      <DataTable
        columns={columns}
        data={filteredApprovedLeave}
        persistTableHead
        highlightOnHover
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
      />
    </div>
  );
}

export default AdminApprovedLeave;
