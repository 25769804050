import React, { useEffect, useMemo, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { ApiCall, apiUrl } from '../api';
import { useAuth } from '../context/AuthContext';
import useFetch from '../hooks/useFetch';
// data table import
import DataTable from '../components/DataTableBase';
import { FilterComponent } from '../components/FilterComponent';

function TicketManagement() {
  const { currentUser, tokenValue } = useAuth();
  const location = useLocation();

  const [ticketData, setTicketData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(
    Number(new URLSearchParams(location.search).get('page')) || 1
  );
  const [filterText, setFilterText] = useState('');
  const [filterTextUser, setFilterTextUser] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const url = `ticket?page=${currentPage}&per_page=${perPage}&search=${filterText}&delay=1`;

  useEffect(() => {
    const fetchTicketData = async () => {
      setIsLoading(true);
      const response = await ApiCall(url, 'get', tokenValue);
      setTicketData(response.data?.ticketData);
      setTotalRows(response?.data?.totalTicket);
      setIsLoading(false);
    };
    fetchTicketData();
  }, [tokenValue, url]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true);
    setPerPage(newPerPage);
    setCurrentPage(page);
    const response = await ApiCall(url, 'get', tokenValue);
    setTicketData(response.data?.ticketData);
    setTotalRows(response?.data?.totalTicket);
    setIsLoading(false);
  };

  // get logged in user tickets
  const {
    data: empTicketData,
    loading,
    error,
  } = useFetch(`/ticket/user/${currentUser._id}`);

  const columns = [
    {
      name: 'Name',
      selector: (row) => (
        <div className="tableImg">
          {row?.employeeInfo?.imgPath && (
            <img
              src={apiUrl + '/' + row?.employeeInfo?.imgPath}
              alt={'Image of ' + row?.employeeInfo?.name}
            />
          )}
          {row?.employeeInfo?.name && <p>{row?.employeeInfo?.name}</p>}
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Title',
      selector: (row) => row?.title,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row?.ticketFinalStatus,
      sortable: true,
    },
    {
      name: 'Ticket Id',
      selector: (row) => row?.ticketNumber,
      sortable: true,
    },
    {
      name: 'Create Date',
      selector: (row) => row?.createDate.split('T')[0],
      sortable: true,
    },
    {
      name: 'Edit',
      selector: (row) => (
        <Link to={`/ticket-review/${row._id}`} state={row} className="btn">
          Review
        </Link>
      ),
    },
  ];

  const userColumns = [
    {
      name: 'Title',
      selector: (row) => row?.title,
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row) => row?.description,
    },
    {
      name: 'Status',
      selector: (row) => row?.ticketFinalStatus,
      sortable: true,
    },
    {
      name: 'Create Date',
      selector: (row) => row?.createDate.split('T')[0],
      sortable: true,
    },
  ];

  // setup filter for search
  const filteredItemsAdminOrSuper = ticketData?.filter(
    (item) =>
      (item.employeeInfo?.name &&
        item.employeeInfo?.name
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item.ticketFinalStatus &&
        item.ticketFinalStatus
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item.title &&
        item.title.toLowerCase().includes(filterText.toLowerCase())) ||
      (item?.ticketNumber && item?.ticketNumber?.includes(filterText))
  );

  const filteredItemsUser = empTicketData?.filter(
    (item) =>
      (item.ticketFinalStatus &&
        item.ticketFinalStatus
          .toLowerCase()
          .includes(filterTextUser.toLowerCase())) ||
      (item.title &&
        item.title.toLowerCase().includes(filterTextUser.toLowerCase()))
  );
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const subHeaderComponentMemoUser = useMemo(() => {
    const handleClear = () => {
      if (filterTextUser) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterTextUser('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterTextUser(e.target.value)}
        onClear={handleClear}
        filterText={filterTextUser}
      />
    );
  }, [filterTextUser, resetPaginationToggle]);
  // setup filter for search ends
  // expandable row configuration
  const ExpandedComponentUser = ({ data }) => (
    <div className="leaveReason">
      <p>{`Ticket Id: ${data?.ticketNumber}`}</p>
      {data?.adminSpecialNote && (
        <p>{`Admin Special Note: ${data?.adminSpecialNote}`}</p>
      )}
      {data?.adminNote && <p>{`Admin Note: ${data?.adminNote}`}</p>}
      {data?.supervisorNote && (
        <p>{`Supervisor Note: ${data?.supervisorNote}`}</p>
      )}
    </div>
  );

  const ExpandedComponent = ({ data }) => (
    <div className="leaveReason">
      <p>{`Title: ${data?.title}`}</p>
      {data?.description && <p>{`Ticket Description: ${data?.description}`}</p>}
      {data?.supervisorNote && (
        <p>{`Supervisor Note: ${data?.supervisorNote}`}</p>
      )}
    </div>
  );
  return (
    <div className="container">
      {error && <h6>Fetching ticket data has an error....</h6>}
      {loading && <h6>Loading Ticket data....</h6>}
      {!loading && (
        <main>
          <div className="headingWithBtn">
            <h1>Ticket Management</h1>
            <div>
              <Link to={'/add-ticket'} className="btn btn-primary">
                Add Ticket
              </Link>
            </div>
          </div>
          {(currentUser?.userRole?.toLowerCase() === 'admin' ||
            currentUser?.userRole?.toLowerCase() === 'supervisor') && (
            <DataTable
              columns={columns}
              data={filteredItemsAdminOrSuper}
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              paginationComponentOptions={{
                rowsPerPageText: 'Rows per page:',
                rangeSeparatorText: 'of',
                noRowsPerPage: false,
                selectAllRowsItem: true,
                selectPerPageItem: false,
              }}
              onChangePage={(page) => setCurrentPage(page)}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationDefaultPage={currentPage}
              progressPending={isLoading}
              persistTableHead
              highlightOnHover
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
            />
          )}

          <h1 className="heading">Ticket Data of {currentUser.name}</h1>
          <DataTable
            columns={userColumns}
            data={filteredItemsUser}
            persistTableHead
            highlightOnHover
            expandableRows
            expandableRowsComponent={ExpandedComponentUser}
            subHeader
            subHeaderComponent={subHeaderComponentMemoUser}
          />
        </main>
      )}
    </div>
  );
}

export default TicketManagement;
