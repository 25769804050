import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../api';
import { FilterComponent } from '../components/FilterComponent';
import useFetch from '../hooks/useFetch';
// data table import
import BackButton from '../components/BackButton';
import DataTable from '../components/DataTableBase';

function LeaveReport() {
  const { data: employeeData, error, loading } = useFetch('/emp');

  //  custom styling of the table.
  const customStyles = {
    rows: {
      style: {
        minHeight: '70px', // override the row height
      },
    },
    table: {
      style: {
        border: '1px solid #EEEEEE', // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: '18px',
        fontWeight: '700',
      },
    },
    cells: {
      style: {
        fontSize: '16px',
        'div:first-child': {
          whiteSpace: 'unset!important',
        },
        img: {
          width: '70px',
          'object-fit': 'cover',
        },
      },
    },
    pagination: {
      style: {
        fontSize: '16px',
      },
    },
  };

  // data table column name setup
  const columns = [
    {
      name: 'Name',
      selector: (row) => (
        <div className='tableImg'>
          {row?.imgPath && (
            <img
              src={apiUrl + '/' + row?.imgPath}
              alt={'Image of ' + row?.name}
            />
          )}
          {row?.name && <p>{row?.name}</p>}
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Total Leave',
      selector: (row) => (row.totalLeave ? row.totalLeave : 0),
    },
    {
      name: 'Available Leave',
      selector: (row) => (row.availableLeave ? row.availableLeave : 0),
    },
    {
      name: 'Consumed Leave',
      selector: (row) => {
        row.consumedLeaves = `Casual: ${row?.consumedCasualLeave}, Sick: ${row?.consumedSickLeave}, 
        Family: ${row?.consumedFamilyLeave}, 
        ${row.consumedSpecialLeave? `Special: ${row.consumedSpecialLeave}, `: ""}
        ${row.consumedHolidayLeave? `Holiday: ${row.consumedHolidayLeave}, `: ""}
        ${row.consumedParenthoodLeave? `Parenthood: ${row.consumedParenthoodLeave}, `: ""}
        ${row.consumedMaritalLeave? `Marital: ${row.consumedMaritalLeave}, `: ""}
        ${row.consumedBonusLeave? `Bonus: ${row.consumedBonusLeave}, `: ""}
        ${row.consumedUnpaidLeave? `Unpaid: ${row.consumedUnpaidLeave}, `: ""}
        Total: ${row?.consumedLeave}`;

        return row.consumedLeaves;
      },
    },
    {
      name: 'Edit',
      selector: (row) => (
        <Link to={`/leave-report/${row?._id}`} className='btn'>
          Leave Report
        </Link>
      ),
    },
  ];
  // setup filter for search
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = employeeData.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.teamName &&
        item.teamName.toLowerCase().includes(filterText.toLowerCase()))
  );
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className='container'>
      {loading && <h6>Loading emp data....</h6>}
      {error && <h6>{error}</h6>}
      {!loading && (
        <main>
          <h1>Leave Report Management</h1>
          <BackButton />
          <DataTable
            columns={columns}
            data={filteredItems}
            customStyles={customStyles}
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            highlightOnHover
          />
        </main>
      )}
    </div>
  );
}

export default LeaveReport;
