import React from 'react';

const EmpSummary = (props) => {
  return (
    <main>
      <h1>Welcome {props.currentUser.name}</h1>
      <div className="infoSummary generalNotice">
        <h2>General Notices</h2>
        <div className="gridRow">
          <div className="shadow-sm">
            <a
              href="https://hrapi.braincraftapps.com/leavePolicy.jpg"
              target="_blank"
              title="Leave Application Policy"
              rel="noopener noreferrer"
            >
              <img
                src="https://hrapi.braincraftapps.com/leavePolicy.jpg"
                alt="Leave Policy Of brain craft"
              />
            </a>
          </div>
          <div className="shadow-sm">
            <a
              href="https://hrapi.braincraftapps.com/guidelines-on-device-usage.jpg"
              target="_blank"
              title="Device Guideline"
              rel="noopener noreferrer"
            >
              <img
                src="https://hrapi.braincraftapps.com/guidelines-on-device-usage.jpg"
                alt="Device Guideline Of brain craft"
              />
            </a>
          </div>
          <div className="shadow-sm">
            <a
              href="https://hrapi.braincraftapps.com/best-etiquettes.jpg"
              target="_blank"
              title="Best etiquettes"
              rel="noopener noreferrer"
            >
              <img
                src="https://hrapi.braincraftapps.com/best-etiquettes.jpg"
                alt="Best etiquettes"
              />
            </a>
          </div>
          <div className="shadow-sm">
            <a
              href="https://hrapi.braincraftapps.com/holiday-calender.jpg"
              target="_blank"
              title="Holiday calendar"
              rel="noopener noreferrer"
            >
              <img
                src="https://hrapi.braincraftapps.com/holiday-calender.jpg"
                alt="Holiday calendar of brain craft"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="infoSummary">
        <div className="gridRow">
          <div className="card dash-widget shadow-sm">
            <div className="card-body">
              <div className="card-icon bg-primary">
                <i className="fas fa-users" aria-hidden="true"></i>
              </div>
              <div className="card-right">
                <h4 className="card-title">Consumed Leave</h4>
                <p className="card-text">
                  {props?.currentUser?.consumedLeave ? props?.currentUser?.consumedLeave : '0'}
                </p>
              </div>
            </div>
          </div>
          <div className="card dash-widget shadow-sm">
            <div className="card-body">
              <div className="card-icon bg-primary">
                <i className="fas fa-user-friends" aria-hidden="true"></i>
              </div>
              <div className="card-right">
                <h4 className="card-title">Available Leave</h4>
                <p className="card-text">
                  {props?.currentUser?.availableLeave ? props?.currentUser?.availableLeave : '0'}
                </p>
              </div>
            </div>
          </div>
          <div className="card dash-widget shadow-sm">
            <div className="card-body">
              <div className="card-icon bg-primary">
                <i className="fas fa-calendar-alt" aria-hidden="true"></i>
              </div>
              <div className="card-right">
                <h4 className="card-title">Total Leave</h4>
                <p className="card-text">{props.currentUser.totalLeave}</p>
              </div>
            </div>
          </div>
          {props?.leaveSummary?.casual !== 0 && (
            <div className="card dash-widget shadow-sm">
              <div className="card-body">
                <div className="card-icon bg-primary">
                  <i className="fas fa-calendar-alt" aria-hidden="true"></i>
                </div>
                <div className="card-right">
                  <h4 className="card-title">Casual Consumed</h4>
                  <p className="card-text">{props.leaveSummary.casual}</p>
                </div>
              </div>
            </div>
          )}

          {props?.leaveSummary?.family !== 0 && (
            <div className="card dash-widget shadow-sm">
              <div className="card-body">
                <div className="card-icon bg-primary">
                  <i className="fas fa-calendar-alt" aria-hidden="true"></i>
                </div>
                <div className="card-right">
                  <h4 className="card-title">Family Consumed</h4>
                  <p className="card-text">{props.leaveSummary.family}</p>
                </div>
              </div>
            </div>
          )}

          {props?.leaveSummary?.sick !== 0 && (
            <div className="card dash-widget shadow-sm">
              <div className="card-body">
                <div className="card-icon bg-primary">
                  <i className="fas fa-calendar-alt" aria-hidden="true"></i>
                </div>
                <div className="card-right">
                  <h4 className="card-title">Sick Consumed</h4>
                  <p className="card-text">{props.leaveSummary.sick}</p>
                </div>
              </div>
            </div>
          )}

          {props?.leaveSummary?.special !== 0 && (
            <div className="card dash-widget shadow-sm">
              <div className="card-body">
                <div className="card-icon bg-primary">
                  <i className="fas fa-calendar-alt" aria-hidden="true"></i>
                </div>
                <div className="card-right">
                  <h4 className="card-title">Special Consumed</h4>
                  <p className="card-text">{props.leaveSummary.special}</p>
                </div>
              </div>
            </div>
          )}

          {props?.leaveSummary?.unpaid !== 0 && (
            <div className="card dash-widget shadow-sm">
              <div className="card-body">
                <div className="card-icon bg-primary">
                  <i className="fas fa-calendar-alt" aria-hidden="true"></i>
                </div>
                <div className="card-right">
                  <h4 className="card-title">Sick Consumed</h4>
                  <p className="card-text">{props?.leaveSummary?.unpaid}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default EmpSummary;
