import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-responsive-modal';
import { apiUrl } from '../api';
import BackButton from '../components/BackButton';
import DataTableBase from '../components/DataTableBase';
import { useAuth } from '../context/AuthContext';

const AddStaticEmail = () => {
  const { tokenValue } = useAuth();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [id, setId] = useState('');
  const [defaultEmail, setDefaultEmail] = useState('');
  const [defaultStatus, setDefaultStatus] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const options = [
    { name: 'Active', id: 'active' },
    { name: 'Inactive', id: 'inactive' },
  ];

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      memberEmail: defaultEmail,
      status: defaultStatus,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${apiUrl}/wildcard/members`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${tokenValue}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          setMessage(error);
          onOpenModal();
        });
    };
    fetchData();
  }, [tokenValue, open]);

  const handleEditRow = (row) => {
    setId(row._id);
    setDefaultEmail(row.memberEmail);
    setDefaultStatus(row.status);
    setValue('memberEmail', row?.memberEmail);
    setValue('status', row?.status);
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    data.memberEmail = data?.memberEmail?.toLowerCase();

    if (id) {
      fetch(`${apiUrl}/wildcard/members/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${tokenValue}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setMessage(data.message || data.error);
          if (data.code === 200) {
            setId('');
            setDefaultEmail('');
            setDefaultStatus('');
            setSubmitting(false);
            reset();
          }
          onOpenModal();
        })
        .catch((error) => {
          setMessage(error);
          setSubmitting(false);
          onOpenModal();
        });
    } else {
      fetch(`${apiUrl}/wildcard/members/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${tokenValue}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setMessage(data.message || data.error);
          if (data.code === 200) {
            reset();
            setSubmitting(false);
          }
          onOpenModal();
        })
        .catch((error) => {
          setMessage(error);
          setSubmitting(false);
          onOpenModal();
        });
    }
  };

  const handleDeleteRow = (row) => {
    fetch(`${apiUrl}/wildcard/members/${row._id}`, {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${tokenValue}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setMessage(data.message || data.error);
        onOpenModal();
      })
      .catch((error) => {
        setMessage(error);
        onOpenModal();
      });
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: '70px', // override the row height
      },
    },
    table: {
      style: {
        border: '1px solid #EEEEEE', // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: '18px',
        fontWeight: '700',
      },
    },
    cells: {
      style: {
        fontSize: '16px',
        'div:first-child': {
          whiteSpace: 'unset!important',
        },
        img: {
          width: '70px',
          'object-fit': 'cover',
        },
      },
    },
    pagination: {
      style: {
        fontSize: '16px',
      },
    },
  };

  const Columns = [
    {
      name: 'Email',
      selector: (row) => row?.memberEmail,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row?.status,
    },
    {
      name: 'Edit',
      selector: (row) => (
        <button onClick={() => handleEditRow(row)} className='btn backBtn'>
          Edit
        </button>
      ),
    },
    {
      name: 'Delete',
      selector: (row) => (
        <button onClick={() => handleDeleteRow(row)} className='btn backBtn'>
          Delete
        </button>
      ),
    },
  ];

  return (
    <main>
      <div className='container'>
        <h1>Add Static Email</h1>
        <BackButton />

        <div className='book__form'>
          <form className='form leaveForm' onSubmit={handleSubmit(onSubmit)}>
            <div className='form-item'>
              <label htmlFor='memberEmail'>Email</label>
              <input
                id='memberEmail'
                className='field_sm'
                type='text'
                {...register('memberEmail', {
                  required: '*email name is required',
                })}
              />
              {errors?.email && (
                <span className='error'>{errors?.email?.message}</span>
              )}
            </div>
            <div className='form-item'>
              <label htmlFor={'status'}> {'Status'} </label>
              <select
                {...register('status')}
                defaultValue={defaultStatus}
                required
              >
                <option value=''>Select Data</option>
                {options?.map((value, index) => (
                  <option key={index} value={value._id}>
                    {value.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-item'>
              <input
                className='btn btn-primary'
                type='submit'
                name='submit'
                value={id ? 'Update' : 'Save'}
                disabled={submitting}
              />
            </div>
          </form>
        </div>

        <DataTableBase
          columns={Columns}
          data={data?.data}
          persistTableHead
          highlightOnHover
          customStyles={customStyles}
        />

        <Modal
          open={open}
          onClose={onCloseModal}
          center
          classNames={{
            modal: 'customModal',
          }}
          animationDuration={800}
        >
          <p>{message}</p>
        </Modal>
      </div>
    </main>
  );
};

export default AddStaticEmail;
