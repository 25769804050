import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '../components/forms/TextInput';
import SelectInput from '../components/forms/SelectInput';
import { apiClient } from '../api';
import { useAuth } from '../context/AuthContext';
import { useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import BackButton from '../components/BackButton';
import { useNavigate } from 'react-router-dom';
// Modal files include
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const TeamEdit = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { data: teamLeadData } = useFetch('/emp');
  let teamLeads = teamLeadData.filter((data) => data.isActive === 'Active');
  const { data: team } = useFetch(`/team/${slug}`);
  // modal configuration data
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    navigate(-1);
  };

  const [msg, setMsg] = useState('');
  const { tokenValue } = useAuth();
  const { register, handleSubmit, reset } = useForm();
  useEffect(() => {
    reset(team);
  }, [reset, team]);

  const onSubmit = (data) => {
    apiClient
      .put('/team/update', data, {
        headers: {
          authorization: `Bearer ${tokenValue}`,
        },
      })
      .then((res) => {
        setMsg(res.data.msg);
        if (res?.data?.msg === 'added successfully') {
          reset();
        }
        onOpenModal();
      })
      .catch((err) => {
        setMsg(err);
        onOpenModal();
      });
  };

  return (
    <main>
      <div className="container">
        <h1>Details of {team?.name} Team</h1>
        <BackButton />
        <div className="book__form">
          <form method="post" className="form teamForm" onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              label="Team Name"
              name="name"
              id="name"
              type="text"
              register={register}
              defaultValue={team?.name}
              required
            />
            <SelectInput
              label="Team Status"
              name="isActive"
              id="isActive"
              defaultValue="Active"
              options={[
                { name: 'Active', id: 'Active' },
                { name: 'Inactive', id: 'Inactive' },
              ]}
              register={register}
              required
            />
            <SelectInput
              label="Team Lead"
              name="teamLeadId"
              id="teamLeadId"
              defaultValue={teamLeads?._id}
              options={teamLeads}
              register={register}
              required
            />
            <input type={'hidden'} defaultValue={slug} {...register('_id')} />
            <div className="form-item">
              <input className="btn btn-primary" type="submit" name="submit" value="Save" />
            </div>
          </form>
          <Modal
            open={open}
            onClose={onCloseModal}
            center
            classNames={{
              modal: 'customModal',
            }}
            animationDuration={800}
          >
            <p>{msg}</p>
          </Modal>
        </div>
      </div>
    </main>
  );
};

export default TeamEdit;
