import React, { useState } from 'react';
//include useForm from react hook form
import { useForm } from 'react-hook-form';
import { apiUrl } from '../api';
import { useAuth } from '../context/AuthContext';
// include form components
import TextInput from './forms/TextInput';
import SelectInput from './forms/SelectInput';
import TextAreaInput from './forms/TextAreaInput';
// include fetch hook
import useFetch from '../hooks/useFetch';
// include back button component
import BackButton from './BackButton';
// modal include
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';

const AddUser = () => {
  // get token value
  const { tokenValue } = useAuth();
  const navigate = useNavigate();
  // get API data
  const { data: empData } = useFetch('/emp');
  const { data: designation } = useFetch('/des');
  const { data: team } = useFetch('/team');
  // set useState variables
  const [msg, setMsg] = useState('');
  const [submitting, setSubmitting] = useState(false);

  // Modal configuration
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    navigate(-1);
  };
  // get supervisor list
  const supervisorList = empData?.filter(
    (supervisor) =>
      supervisor.userRole === 'supervisor' && supervisor.isActive === 'Active'
  );
  // register react hook form
  const { register, handleSubmit } = useForm();
  // get active team and designation
  const activeTeams = team.filter((data) => data.isActive === 'Active');
  const activeDesignations = designation.filter(
    (data) => data.isActive === 'Active'
  );
  // form submit function
  const onSubmit = (data) => {
    setSubmitting(true);
    const formData = new FormData();
    for (const key in data) {
      if (key !== 'nid' && key !== 'image' && key !== 'tin') {
        formData.append(key, data[key]);
      } else {
        formData.append(key, data[key][0]);
      }
    }
    fetch(`${apiUrl}/emp/add`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${tokenValue}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setMsg(data.msg || data.error);
        setSubmitting(false);
        onOpenModal();
      })
      .catch((error) => {
        setMsg(error);
        setSubmitting(false);
        onOpenModal();
      });
  };
  // set user role
  const userRole = [
    {
      _id: 'admin',
      name: 'Admin',
    },
    {
      _id: 'supervisor',
      name: 'Supervisor',
    },
    {
      _id: 'employee',
      name: 'Employee',
    },
  ];
  // set blood groups
  const bloodGroup = [
    {
      _id: 'A+',
      name: 'A+',
    },
    {
      _id: 'A-',
      name: 'A-',
    },
    {
      _id: 'B+',
      name: 'B+',
    },
    {
      _id: 'B-',
      name: 'B-',
    },
    {
      _id: 'O+',
      name: 'O+',
    },
    {
      _id: 'O-',
      name: 'O-',
    },
    {
      _id: 'AB+',
      name: 'AB+',
    },
    {
      _id: 'AB-',
      name: 'AB-',
    },
  ];

  return (
    <main>
      <div className="container">
        <h1>Add Details of Employee</h1>
        <BackButton />
        <div className="book__form">
          <form method="post" className="form empForm" onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              label="Full Name"
              name="name"
              id="name"
              type="text"
              register={register}
              required
            />
            <TextInput
              label="Phone Number"
              name="contactNumber"
              id="contactNumber"
              type="text"
              title="Only Number and 11 character Length"
              pattern="[0-9]{0}[0-9]{11}"
              register={register}
            />
            <TextInput
              label="Email Address"
              name="email"
              id="email"
              type="email"
              register={register}
            />
            <SelectInput
              label="Team"
              name="teamId"
              id="teamId"
              options={activeTeams}
              register={register}
              required
            />
            <SelectInput
              label="Designation"
              name="designationId"
              id="designationId"
              options={activeDesignations}
              register={register}
              required
            />
            <SelectInput
              label="Supervisor"
              name="supervisorId"
              id="supervisorId"
              options={supervisorList}
              register={register}
            />
            <TextInput
              label="NID"
              name="nationalIdNumber"
              id="nationalIdNumber"
              type="number"
              register={register}
            />
            <TextInput label="NID File" name="nid" id="nid" type="file" register={register} />
            <TextInput label="TIN File" name="tin" id="tin" type="file" register={register} />
            <SelectInput
              label="Blood Group"
              name="bloodGroup"
              id="bloodGroup"
              options={bloodGroup}
              register={register}
            />
            <TextAreaInput
              label="Present Address"
              name="presentAddress"
              id="presentAddress"
              register={register}
            />
            <TextAreaInput
              label="Permanent Address"
              name="permanentAddress"
              id="permanentAddress"
              register={register}
            />
            <TextInput
              label="Emergency Contact"
              name="emergencyContactDetails"
              id="emergencyContactDetails"
              title="Only Number and 11 character Length"
              pattern="[0-9]{0}[0-9]{11}"
              register={register}
            />
            <TextInput
              label="Join Date"
              name="joiningDate"
              id="joiningDate"
              type="date"
              register={register}
            />
            <TextInput
              label="Profile Pic"
              name="image"
              id="image"
              type="file"
              register={register}
            />
            <TextInput label="DOB" name="dob" id="dob" type="date" register={register} />
            <TextInput
              label="Date of Termination"
              name="dateOfTermination"
              id="dateOfTermination"
              type="date"
              register={register}
            />
            <TextInput
              label="Total Leave"
              name="totalLeave"
              id="totalLeave"
              type="number"
              defaultValue={0}
              register={register}
            />
            <TextInput
              label="Sick Leave"
              name="sickLeave"
              id="sickLeave"
              type="number"
              defaultValue={0}
              register={register}
              />
            <TextInput
              label="Casual Leave"
              name="casualLeave"
              id="casualLeave"
              type="number"
              defaultValue={0}
              register={register}
            />
            <TextInput
              label="Family Leave"
              name="familyLeave"
              id="familyLeave"
              type="number"
              defaultValue={0}
              register={register}
            /> 
            <TextInput
              label="Parenthood Leave"
              name="parenthoodLeave"
              id="parenthoodLeave"
              type="number"
              defaultValue={0}
              register={register}
            /> 
            <TextInput
              label="Marital Leave"
              name="maritalLeave"
              id="maritalLeave"
              type="number"
              defaultValue={0}
              register={register}
            /> 
            <TextInput
              label="Bonus Leave"
              name="bonusLeave"
              id="bonusLeave"
              type="number"
              defaultValue={0}
              register={register}
            /> 
            <TextInput
              label="Available Leave"
              name="availableLeave"
              id="availableLeave"
              type="number"
              defaultValue={0}
              register={register}
            />
            <TextInput
              label="Consumed Leave"
              name="consumedLeave"
              id="consumedLeave"
              type="number"
              defaultValue={0}
              register={register}
            />
            <SelectInput
              label="Employee Status"
              name="isActive"
              id="isActive"
              options={[
                { name: 'Active', id: 1 },
                { name: 'Inactive', id: 0 },
              ]}
              register={register}
              required
            />
            <SelectInput
              label="User Role"
              name="userRole"
              id="id"
              options={userRole}
              register={register}
              required
            />
            <div className="form-item">
              <input className="btn btn-primary" type="submit" name="submit" value="Save" disabled={submitting}/>
            </div>
          </form>
          <Modal
            open={open}
            onClose={onCloseModal}
            center
            classNames={{
              modal: 'customModal',
            }}
            animationDuration={800}
          >
            <p>{msg}</p>
          </Modal>
        </div>
      </div>
    </main>
  );
};

export default AddUser;
