import React from 'react';
import Employees from '../components/Employees';

const EmployeeManagement = () => {
  return (
    <div className="container">
      <main>
        <Employees></Employees>
      </main>
    </div>
  );
};

export default EmployeeManagement;
