import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';
import BackButton from '../components/BackButton';
import SelectInput from '../components/forms/SelectInput';
import TextAreaInput from '../components/forms/TextAreaInput';
import { useAuth } from '../context/AuthContext';

function EditTicket() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState('');
  // modal configuration data
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    navigate(-1);
  };
  /**
   * TODO
   * get the application data
   */
  const ticketInfo = {
    supervisorApprovalStatus: 'approved',
    supervisorNote: ' This will help his productivity',
    employeeInfo: {
      name: 'Anamul Nadim',
    },
    ticketTitle: 'This a dummy ticket title',
    ticketDescription:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla temporibus et, labore distinctio deserunt autem possimus recusandae suscipit tempore excepturi!',
  };
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    /**
     * TODO
     * send data to server
     * set the server response to modal
     */
    console.log(data);
    setData('Data submitted');
    onOpenModal();
  };
  return (
    <main>
      <div className="container">
        {/* {loading && <h6>Loading emp data....</h6>}
			{error && <h6>{error}</h6>} */}
        <>
          <h1>Review ticket of Name</h1>
          <BackButton />
          <div className="ticketInfo">
            <div className="ticketInfo-item">
              <h3>Ticket Request From:</h3>
              <p>
                {ticketInfo?.employeeInfo?.name
                  ? ticketInfo?.employeeInfo?.name
                  : 'Nadim'}
              </p>
            </div>
            <div className="ticketInfo-item">
              <h3>Ticket Title:</h3>
              <p>{ticketInfo?.ticketTitle}</p>
            </div>
            <div className="ticketInfo-item">
              <h3>Ticket Description:</h3>
              <p>{ticketInfo?.ticketDescription}</p>
            </div>
            {currentUser.userRole?.toLowerCase() === 'admin' && (
              <>
                {ticketInfo?.supervisorApprovalStatus && (
                  <div className="ticketInfo-item">
                    <h3>Supervisor Approval Status:</h3>
                    <p>{ticketInfo?.supervisorApprovalStatus}</p>
                  </div>
                )}
                {ticketInfo?.supervisorNote && (
                  <div className="ticketInfo-item">
                    <h3>Supervisor Note:</h3>
                    <p>{ticketInfo?.supervisorNote}</p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="book__form">
            <form
              method="post"
              className="form teamForm"
              onSubmit={handleSubmit(onSubmit)}
            >
              {currentUser.userRole?.toLowerCase() === 'admin' && (
                <>
                  <SelectInput
                    label="Ticket Status"
                    name="adminStatus"
                    id="adminStatus"
                    options={[
                      { name: 'Approved', id: 'approved' },
                      { name: 'Reject', id: 'reject' },
                    ]}
                    register={register}
                  />
                  <TextAreaInput
                    label="Note"
                    name="adminNote"
                    id="adminNote"
                    register={register}
                  />
                </>
              )}
              {currentUser.userRole?.toLowerCase() === 'supervisor' && (
                <>
                  <SelectInput
                    label="Ticket Status"
                    name="supervisorStatus"
                    id="supervisorStatus"
                    options={[
                      { name: 'Approved', id: 'approved' },
                      { name: 'Reject', id: 'reject' },
                    ]}
                    register={register}
                  />
                  <TextAreaInput
                    label="Note"
                    name="supervisorNote"
                    id="supervisorNote"
                    register={register}
                  />
                </>
              )}
              <div className="form-item">
                <input
                  className="btn btn-primary"
                  type="submit"
                  name="submit"
                  value="Save"
                />
              </div>
            </form>
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              classNames={{
                modal: 'customModal',
              }}
              animationDuration={800}
            >
              <p>{data}</p>
            </Modal>
          </div>
        </>
      </div>
    </main>
  );
}

export default EditTicket;
