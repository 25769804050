import style from './Login.module.css';
import { apiUrl } from '../api';
import googleLoge from '../resources/google-logo.png';

const Login = () => {
  const msg = sessionStorage.getItem('failed');
  sessionStorage.removeItem('failed');

  return (
    <div className={style.loginBox}>
      {msg && <p>{msg}</p>}
      <h2>Welcome to Brain Craft Ltd.</h2>
      <a
        href={`${apiUrl}/login/auth/google`}
        className={`${style.socialButton}`}
      >
        <img className={style.gLogo} src={googleLoge} alt="Google Logo" />
        <span>Login with Google</span>
      </a>
    </div>
  );
};

export default Login;
