import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiUrl } from '../api';
import BackButton from '../components/BackButton';
import useFetch from '../hooks/useFetch';
// data table import
import DataTable from '../components/DataTableBase';
import ExportCsv from '../components/ExportCsv';

function UserLeaveReport() {
  const { slug } = useParams();
  const { data: employeeData, error, loading } = useFetch(`/emp/${slug}`);
  const { data: employeeLeaveData } = useFetch(`/leave/report/${slug}`);
  const [userInfo, setUserInfo] = useState({});
  // data table column
  const columns = [
    {
      name: 'Start Date',
      selector: (row) => row?.startDate.split('T')[0],
      sortable: true,
    },
    {
      name: 'End Date',
      selector: (row) => row?.endDate.split('T')[0],
      sortable: true,
    },
    {
      name: 'Leave For',
      selector: (row) => row?.count,
    },
    {
      name: 'Leave Type',
      selector: (row) => row?.type,
      sortable: true,
    },
  ];
  // setup filter by date for search
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');
  let filterByDate = employeeLeaveData; // initial value is all leave data
  const currentDate = new Date();
  // Assuming you want the default start date to be January 1st of the current year
  const defaultStartDate = `${currentDate.getFullYear()}-01-01`;
  const defaultEndDate = `${currentDate.getFullYear()}-12-31`;
  useEffect(() => {
    setFilterStartDate(defaultStartDate);
    setFilterEndDate(defaultEndDate);
  }, [defaultStartDate, defaultEndDate]);
  useEffect(() => {
    const dateRange = {};
    if (filterStartDate && filterEndDate) {
      dateRange.startDate = filterStartDate;
      dateRange.endDate = filterEndDate;
    }
    const leaveCountInfo = {
      casualLeaveCount: 0,
      sickLeaveCount: 0,
      specialLeaveCount: 0,
      familyLeaveCount: 0,
      holidayLeaveCount: 0,
      parenthoodLeaveCount: 0,
      maritalLeaveCount: 0,
      bonusLeaveCount: 0,
      unpaidLeaveCount: 0,
    };
    filterByDate.forEach((leave) => {
      if (leave?.type.toLowerCase() === 'casual') {
        leaveCountInfo.casualLeaveCount += leave.count;
      }
      if (leave?.type.toLowerCase() === 'sick') {
        leaveCountInfo.sickLeaveCount += leave.count;
      }
      if (leave?.type.toLowerCase() === 'special Leave') {
        leaveCountInfo.specialLeaveCount += leave.count;
      }
      if (leave?.type.toLowerCase() === 'family') {
        leaveCountInfo.familyLeaveCount += leave.count;
      }
      if (leave?.type.toLowerCase() === 'holiday') {
        leaveCountInfo.holidayLeaveCount += leave.count;
      }
      if (leave?.type.toLowerCase() === 'parenthood') {
        leaveCountInfo.parenthoodLeaveCount += leave.count;
      }
      if (leave?.type.toLowerCase() === 'marital') {
        leaveCountInfo.maritalLeaveCount += leave.count;
      }
      if (leave?.type.toLowerCase() === 'bonus') {
        leaveCountInfo.bonusLeaveCount += leave.count;
      }
      if (leave?.type.toLowerCase() === 'unpaid') {
        leaveCountInfo.unpaidLeaveCount += leave.count;
      }
    });

    setUserInfo({
      name: employeeData?.name,
      totalLeave: employeeData?.totalLeave,
      availableLeave: employeeData?.availableLeave,
      consumedLeave: employeeData?.consumedLeave,
      ...leaveCountInfo,
      ...dateRange,
    });
  }, [employeeData, filterStartDate, filterEndDate, filterByDate]);
  // if there is filter date filter the leave data accordingly
  if (filterStartDate && filterEndDate) {
    let startDate = new Date(filterStartDate).setHours(0, 0, 0, 0);
    let endDate = new Date(filterEndDate).setHours(0, 0, 0, 0);
    filterByDate = filterByDate.filter((application) => {
      let leaveStartDate = new Date(
        application.startDate.split('T')[0]
      ).setHours(0, 0, 0, 0);
      if (leaveStartDate >= startDate && leaveStartDate <= endDate) {
        return true;
      }
      return false;
    });
  }
  // expandable row configuration
  const ExpandedComponent = ({ data }) => (
    <div className="leaveReason">
      <p>{`Reason: ${data?.reason}`}</p>
      {data?.adminNote && <p>{`Admin Note: ${data?.adminNote}`}</p>}
      {(data?.leavePenalty > 0 || data?.leavePenalty < 0) && (
        <p>{`Leave Adjusted by: ${data.leavePenalty} ${data.leavePenalty > 1 ? 'days' : 'day'}`}</p>
      )}
    </div>
  );
  // csv button settings
  const actionsMemo = useMemo(
    () => <ExportCsv onExport={filterByDate} userInfo={userInfo} />,
    [userInfo, filterByDate]
  );

  if (!loading && employeeLeaveData.length === 0) {
    return (
      <div className='container'>
        <h1 className='mt-30'>No Consumed Leave for {employeeData?.name}</h1>
        <BackButton />
      </div>
    );
  }

  return (
    <main>
      <div className='container'>
        {loading && <h6>Loading leave data....</h6>}
        {error && <h6>{error}</h6>}
        {!loading && (
          <>
            <div className='empNameImg'>
              <h1>Leave Report of {employeeData?.name}</h1>
              <div className='profileImg'>
                <img
                  src={apiUrl + '/' + employeeData?.imgPath}
                  alt={'Image of' + employeeData?.name}
                />
              </div>
            </div>
            <BackButton />
            <div className='gridRow book__form filter'>
              <h3>Select Date Range For Filter</h3>
              <div className='form-item'>
                <label htmlFor={'startDate'}> Start Date </label>
                <input
                  type='date'
                  name='startDate'
                  id='startDate'
                  className='field_sm'
                  onChange={(e) => setFilterStartDate(e.target.value)}
                  defaultValue={defaultStartDate}
                />
              </div>
              <div className='form-item'>
                <label htmlFor={'endDate'}> End Date </label>
                <input
                  type='date'
                  name='endDate'
                  id='endDate'
                  className='field_sm'
                  onChange={(e) => setFilterEndDate(e.target.value)}
                  defaultValue={defaultEndDate}
                />
              </div>
            </div>
            <DataTable
              columns={columns}
              data={filterByDate}
              persistTableHead
              highlightOnHover
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              actions={actionsMemo}
            />
          </>
        )}
      </div>
    </main>
  );
}

export default UserLeaveReport;
