import React from 'react';

const SelectInput = ({ register, options, name, ...rest }) => {
  return (
    <div className='form-item'>
      <label htmlFor={rest._id}> {rest.label} </label>
      <select {...register(name)} {...rest} required>
        <option value=''>Select Data</option>
        {options?.map((value, index) => (
          <option key={index} value={value._id}>
            {value.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
