import React from 'react';
import { useAuth } from '../context/AuthContext';
import useFetch from '../hooks/useFetch';
import EmpSummary from './EmpSummary';
// import TakeATour from './TakeATour';
import AdminHomeView from './AdminHomeView';
import EmployeeHomeView from './EmployeeHomeView';

const Home = (props) => {
  const { currentUser } = useAuth();
  const {
    data: currentEmp,
    error,
    loading,
  } = useFetch(`/emp/${currentUser?._id}`);
  const currentYear = new Date().getFullYear();
  const { data: leaveSummary } = useFetch(
    `/leave/individualSummary/${currentUser?._id}/${currentYear}`
  );

  return (
    <div className="container">
      {loading && <h6>Loading emp data....</h6>}
      {error && <h6>{error}</h6>}

      {((!loading && currentUser?.userRole?.toLowerCase() === 'supervisor') ||
        (!loading && currentUser?.userRole?.toLowerCase() === 'employee')) && (
        <EmpSummary currentUser={currentEmp} leaveSummary={leaveSummary} />
      )}

      <AdminHomeView
        userRole={currentUser?.userRole}
        totalActiveEmp={currentEmp?.totalActiveEmployee}
      />

      <EmployeeHomeView empId={currentUser?._id} empName={currentUser?.name} />
      {/* {!loading && <TakeATour />} */}
    </div>
  );
};

export default Home;
