import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Failure from '../RedirectPage/failure';
import Success from '../RedirectPage/success';
import AddUser from '../components/AddUser';
import EditUser from '../components/EditUser';
import Home from '../components/Home';
import { useAuth } from '../context/AuthContext';
import AddTicket from '../pages/AddTicket';
import DesignationEdit from '../pages/DesignationEdit';
import EditTicket from '../pages/EditTicket';
import EmployeeManagement from '../pages/EmployeeManagement';
import LeaveApplication from '../pages/LeaveApplication';
import LeaveManagement from '../pages/LeaveManagement';
import LeaveReport from '../pages/LeaveReport';
import LeaveReview from '../pages/LeaveReview';
import Login from '../pages/Login';
import Profile from '../pages/Profile';
import TeamEdit from '../pages/TeamEdit';
import TeamManagement from '../pages/TeamManagement';
import TeamMember from '../pages/TeamMember';
import TicketManagement from '../pages/TicketManagement';
import TicketReview from '../pages/TicketReview';
import UserLeaveReport from '../pages/UserLeaveReport';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
// import Invoice from '../pages/Invoice';
import AddHoliday from '../pages/AddHoliday';
import AddInvoice from '../pages/AddInvoice';
import AddStaticEmail from '../pages/AddStaticEmail';
import Assets from '../pages/Assets';
import DesignationAdd from '../pages/DesignationAdd';
import EditInvoice from '../pages/EditInvoice';
import LeaveAdjust from '../pages/LeaveAdjust';
import TeamAdd from '../pages/TeamAdd';
import ApplyForHolidayLeave from '../pages/ApplyHolidayLeave';
import HolidayLeaveManagement from '../pages/HolidayLeaveManagement';
import ApplicationReview from '../pages/ApplicationReview';

const AllRoutes = () => {
  const { currentUser } = useAuth();
  return (
    <Routes>
      <Route path='/success/:token' element={<Success />} />
      <Route path='/failure' element={<Failure />} />
      <Route path={'/*'} element={<PublicRoute />}>
        <Route path={''} element={<Login />} />
      </Route>
      <Route path={'/*'} element={<PrivateRoute />}>
        <Route path={'dashboard'} element={<Home />} />
        <Route path='add-holiday' element={<AddHoliday />} />
        <Route path='apply-holiday-leave' element={<ApplyForHolidayLeave />} />
        {currentUser?.userRole === 'admin' && (
          <>
            <Route path={'team-management'} element={<TeamManagement />} />
            <Route
              path={'employee-management'}
              element={<EmployeeManagement />}
            />
            <Route path={'leave-management'} element={<LeaveManagement />} />
            <Route path='leave-report' element={<LeaveReport />} />
            <Route path='leave-report/:slug' element={<UserLeaveReport />} />
            <Route path='addUser' element={<AddUser />}>
              <Route path=':slug' element={<AddUser />} />
            </Route>
            <Route path='edit-user/:slug' element={<EditUser />}></Route>
            <Route path='team-edit' element={<TeamEdit />}>
              <Route path=':slug' element={<TeamEdit />} />
            </Route>
            <Route path='team-add' element={<TeamAdd />} />
            <Route path='designation-edit' element={<DesignationEdit />}>
              <Route path=':slug' element={<DesignationEdit />} />
            </Route>
            <Route path='designation-add' element={<DesignationAdd />} />
            <Route path='assets' element={<Assets />} />
            {/* <Route path="invoice" element={<Invoice />} /> */}
            <Route path='add-invoice' element={<AddInvoice />} />
            <Route path='edit-invoice/:slug' element={<EditInvoice />} />
            <Route path='leave-adjust' element={<LeaveAdjust />}>
              <Route path=':slug' element={<LeaveAdjust />} />
            </Route>
            <Route path='add-static-email' element={<AddStaticEmail />} />
            <Route path='holiday-leave-application' element={<HolidayLeaveManagement />} />
            <Route path='application-review/:slug' element={<ApplicationReview />} />
          </>
        )}
        {(currentUser?.userRole === 'supervisor' ||
          currentUser?.userRole === 'admin') && (
          <>
            <Route path='edit-ticket/:slug' element={<EditTicket />} />
          </>
        )}
        {currentUser?.userRole === 'supervisor' && (
          <>
            <Route path={'team-member'} element={<TeamMember />} />
          </>
        )}
        <Route path='leave-review' element={<LeaveReview />}>
          <Route path=':slug' element={<LeaveReview />} />
        </Route>
        <Route path='ticket-review/:slug' element={<TicketReview />}></Route>
        <Route path='profile' element={<Profile />} />
        <Route path='leave-application' element={<LeaveApplication />} />
        <Route path={'ticket-management'} element={<TicketManagement />} />
        <Route path={'add-ticket'} element={<AddTicket />} />
      </Route>
    </Routes>
  );
};

export default AllRoutes;
