import React from 'react';
// data table import
import DataTable from '../components/DataTableBase';

function RejectedLeave(props) {
  const rejectedLeave = props.rejectedLeave;
  const columns = [
    {
      name: 'Name',
      selector: (row) => (row?.name ? row?.name : row?.employeeInfo?.name),
      sortable: true,
    },
    {
      name: 'Leave For',
      selector: (row) => row?.count,
    },
    {
      name: 'Start Date',
      selector: (row) => row?.startDate.split('T')[0],
      sortable: true,
    },
    {
      name: 'End Date',
      selector: (row) => row?.endDate.split('T')[0],
      sortable: true,
    },
    {
      name: 'Leave Type',
      selector: (row) => row?.type,
      sortable: true,
    },
  ];
  // expandable row configuration
  const ExpandedComponent = ({ data }) => (
    <>
      <p className='leaveReason'>{`Reason: ${data?.reason}`}</p>
      {data?.adjustmentNote && (
        <p className='leaveReason'>{`Admin Note: ${data?.adjustmentNote}`}</p>
      )}
    </>
  );
  if (rejectedLeave.length === 0) {
    return <h2 className='mt-30'>No Rejected Leave application</h2>;
  }
  return (
    <>
      {rejectedLeave.length > 0 && (
        <div className='pendingLeave'>
          <h2>Rejected Leave applications</h2>
          <DataTable
            columns={columns}
            data={rejectedLeave}
            persistTableHead
            highlightOnHover
            expandableRows
            expandableRowsComponent={ExpandedComponent}
          />
        </div>
      )}
    </>
  );
}

export default RejectedLeave;
