import React from 'react';
import { Link } from 'react-router-dom';
import AdminApprovedLeave from '../components/AdminApprovedLeave';
import PendingLeave from '../components/PendingLeave';
import RejectedLeave from '../components/RejectedLeave';
import TodaysLeave from '../components/TodaysLeave';
import { useAuth } from '../context/AuthContext';
import useFetch from '../hooks/useFetch';

const LeaveManagement = (props) => {
  const { currentUser, loading, error } = useAuth();

  //get all leave applications
  const { data: adminPendingLeave } = useFetch('/leave/getAdminPendingLeave');
  const { data: approvedLeave } = useFetch('/leave/getApprovedLeave');
  const { data: rejectedLeave } = useFetch('/leave/getRejectedLeave');
  const { data: todaysLeave } = useFetch('/leave/todaysLeave');

  return (
    <main>
      <div className='container'>
        {loading && <h6>Loading Leave data....</h6>}
        {error && <h6>{error}</h6>}
        {!loading && !error && (
          <>
            <div className='headingWithBtn'>
              <h1>Leave Management</h1>
              {currentUser?.userRole === 'admin' && (
                <div className='leaveInfo-item'>
                  <Link to={'/holiday-leave-application'} className='btn btn-primary'>
                    Holiday Leave Applications
                  </Link>
                  <Link to={'/add-holiday'} className='btn btn-primary'>
                    Add Holiday
                  </Link>
                  <Link to={'/leave-report'} className='btn btn-primary'>
                    Leave Report
                  </Link>
                </div>
              )}
            </div>
            {currentUser?.userRole === 'admin' && (
              <>
                <TodaysLeave todaysLeave={todaysLeave} />
                <PendingLeave pendingLeaveData={adminPendingLeave} />
                <AdminApprovedLeave approvedLeave={approvedLeave} />
                <RejectedLeave rejectedLeave={rejectedLeave} />
              </>
            )}
          </>
        )}
      </div>
    </main>
  );
};

export default LeaveManagement;
