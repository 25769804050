import { useReducer, useEffect } from 'react';
import { apiClient } from '../api';
import { useAuth } from '../context/AuthContext';

const initialValue = {
    loading: true,
    error: '',
    data: [],
    status: '',
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SUCCESS':
            return {
                loading: false,
                error: '',
                data: action.payload,
                status: action.status,
            };
        case 'ERROR':
            return {
                loading: false,
                error: 'There was a problem',
                data: {},
                status: action.status,
            };
        default:
            return state;
    }
};

const useFetch = (endPoint) => {
    const { tokenValue } = useAuth();
    //console.log('token value', tokenValue);
    const [state, dispatch] = useReducer(reducer, initialValue);

    useEffect(() => {
        const getData = async () => {
            await apiClient
                .get(endPoint, {
                    headers: {
                        authorization: `Bearer ${tokenValue}`,
                    },
                })
                .then((res) => {
                    res.data.joiningDate = res.data.joiningDate?.split('T')[0];
                    res.data.dob = res.data.dob?.split('T')[0];
                    res.data.dateOfTermination = res.data.dateOfTermination?.split('T')[0];
                    if (res.data.dateOfTermination ==='1970-01-01') {
                        res.data.dateOfTermination = '';
                    }
                    dispatch({
                        type: 'SUCCESS',
                        payload: res.data,
                        status: res.status,
                    });
                })
                .catch(() => {
                    dispatch({ type: 'ERROR' });
                });
        };
        getData();
    }, [endPoint, tokenValue]);

    const { data, status, error, loading } = state;

    return { data, status, error, loading };
};

export default useFetch;
