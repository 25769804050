import axios from 'axios';
export const apiUrl = '//hrapi.braincraftapps.com';

export const apiClient = axios.create({
  baseURL: apiUrl,
  timeout: 100000,
  headers: {
    // 'Content-Type': 'application/json',
    // 'authorization' : `Bearer ${token}`,
    'Allow-Control-Allow-Origin': '*',
  },
});

export const ApiCall = async (url, method = 'get', token, data = null) => {
  try {
    const instance = axios.create({
      baseURL: apiUrl,
      timeout: 100000,
      headers: {
        authorization: `Bearer ${token}`,
        'Allow-Control-Allow-Origin': '*',
      },
    });

    const response = await instance({
      method: method,
      url: url,
      data: data,
    });
    return response;
  } catch (error) {
    throw new Error(error.response.data.error || 'Network error');
  }
};
