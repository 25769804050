import useFetch from '../hooks/useFetch';
import HolidayLeaveManagement from '../pages/HolidayLeaveManagement';
import AdminSummary from './AdminSummary';
import PendingLeave from './PendingLeave';
import TodaysLeave from './TodaysLeave';

const AdminHomeView = ({ userRole, totalActiveEmp }) => {
  const { data: pendingLeaveData } = useFetch('/leave/getAdminPendingLeave');
  const { data: todaysLeave } = useFetch('/leave/todaysLeave');
  return (
    <>
      {userRole?.toLowerCase() === 'admin' && (
        <AdminSummary
          activeUser={totalActiveEmp}
          pendingLeave={pendingLeaveData?.length}
          todaysLeave={todaysLeave?.length}
        ></AdminSummary>
      )}

      {(userRole?.toLowerCase() === 'admin' ||
        userRole?.toLowerCase() === 'supervisor') && (
        <TodaysLeave todaysLeave={todaysLeave} />
      )}

      {userRole?.toLowerCase() === 'admin' && (
        <>
        <PendingLeave pendingLeaveData={pendingLeaveData} />
        <HolidayLeaveManagement title = "Holiday Leave Adjustment Request" action={true} status="pending" dashboardView={true}/>
        </>
      )}
    </>
  );
};

export default AdminHomeView;
