import React from 'react';
import { Link } from 'react-router-dom';

function Assets() {
  return (
    <main>
      <div className="container">
        <h1>Assets Management</h1>
        <div className="gridRow">
          <div className="assetsLink">
            {' '}
            <Link to={`/invoice/`} className="btn linkBtn">
              Invoice Management
            </Link>
          </div>
          <div className="assetsLink">
            {' '}
            <Link to={`/assets-management/`} className="btn linkBtn">
              Assets Management
            </Link>
          </div>
          <div className="assetsLink">
            <Link to={`/assets-report/`} className="btn linkBtn">
              Report
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Assets;
