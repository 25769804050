import React from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../api';
import CardHeroImg from './CardHeroImg';
import CardLeaveInfo from './CardLeaveInfo';
const Card = (props) => {
  return (
    <div className='card dash-widget leaveCard shadow-sm'>
      <div className='card-body'>
        {/* for pending leave data */}
        {props?.pendingLeave && (
          <>
            {props?.pendingLeave?.employeeInfo?.imgPath && (
              <CardHeroImg
                imageLink={
                  apiUrl + '/' + props?.pendingLeave?.employeeInfo?.imgPath
                }
                imageOf={props?.pendingLeave?.employeeInfo?.name}
              />
            )}
            <CardLeaveInfo
              employeeName={props?.pendingLeave?.employeeInfo?.name}
              employeePhone={props?.pendingLeave?.employeeInfo?.contactNumber}
              consumedLeave={props?.pendingLeave?.employeeInfo?.consumedLeave}
              availableLeave={props?.pendingLeave?.employeeInfo?.availableLeave}
              availableSickLeave={
                props?.pendingLeave?.sickLeave -
                props?.pendingLeave?.consumedSickLeave
              }
              availableCasualLeave={
                props?.pendingLeave?.casualLeave -
                props?.pendingLeave?.consumedCasualLeave
              }
              availableFamilyLeave={
                props?.pendingLeave?.familyLeave -
                props?.pendingLeave?.consumedFamilyLeave
              }
              leaveCount={props?.pendingLeave?.count}
              leaveStartDate={props.pendingLeave.startDate.split('T')[0]}
              leaveEndDate={props.pendingLeave.endDate.split('T')[0]}
              leavePenalty={props?.pendingLeave?.leavePenalty}
            />
            {props?.leaveLink && (
              <Link
                to={props.leaveLink}
                state={props.pendingLeave}
                className='btn'
              >
                Review Application
              </Link>
            )}
          </>
        )}

        {/* for approved leave data */}
        {props?.approvedLeave && (
          <>
            {props?.approvedLeave?.employeeInfo?.imgPath && (
              <CardHeroImg
                imageLink={
                  apiUrl + '/' + props?.approvedLeave?.employeeInfo?.imgPath
                }
                imageOf={props?.approvedLeave?.employeeInfo?.name}
              />
            )}
            <CardLeaveInfo
              employeeName={props?.approvedLeave?.employeeInfo?.name}
              employeePhone={props?.approvedLeave?.employeeInfo?.contactNumber}
              consumedLeave={props?.approvedLeave?.employeeInfo?.consumedLeave}
              availableLeave={
                props?.approvedLeave?.employeeInfo?.availableLeave
              }
              leaveCount={props?.pendingLeave?.count}
              leaveStartDate={props.approvedLeave.startDate.split('T')[0]}
              leaveEndDate={props.approvedLeave.endDate.split('T')[0]}
            />
          </>
        )}
        {/* for Todays leave data */}
        {props?.todaysLeave && (
          <>
            {props?.todaysLeave?.employeeInfo?.imgPath && (
              <CardHeroImg
                imageLink={
                  apiUrl + '/' + props?.todaysLeave?.employeeInfo?.imgPath
                }
                imageOf={props?.todaysLeave?.employeeInfo?.name}
              />
            )}
            <CardLeaveInfo
              employeeName={props?.todaysLeave?.name}
              employeePhone={props?.todaysLeave?.contactNumber}
              consumedLeave={props?.todaysLeave?.consumedLeave}
              availableLeave={props?.todaysLeave?.availableLeave}
              leaveCount={props?.pendingLeave?.count}
              leaveStartDate={props.todaysLeave.startDate.split('T')[0]}
              leaveEndDate={props.todaysLeave.endDate.split('T')[0]}
            />
          </>
        )}

        {/* for rejected leave data */}
        {props?.rejectedLeave && (
          <>
            {props?.rejectedLeave?.employeeInfo?.imgPath && (
              <CardHeroImg
                imageLink={
                  apiUrl + '/' + props?.rejectedLeave?.employeeInfo?.imgPath
                }
                imageOf={props?.rejectedLeave?.employeeInfo?.name}
              />
            )}
            <CardLeaveInfo
              employeeName={props?.rejectedLeave?.employeeInfo?.name}
              employeePhone={props?.rejectedLeave?.employeeInfo?.contactNumber}
              consumedLeave={props?.rejectedLeave?.employeeInfo?.consumedLeave}
              availableLeave={
                props?.rejectedLeave?.employeeInfo?.availableLeave
              }
              leaveCount={props?.pendingLeave?.count}
              leaveStartDate={props.rejectedLeave.startDate.split('T')[0]}
              leaveEndDate={props.rejectedLeave.endDate.split('T')[0]}
            />
          </>
        )}

        {/* for employee data */}
        {props?.employeeInfo && (
          <>
            {props?.employeeInfo?.imgPath && (
              <CardHeroImg
                imageLink={apiUrl + '/' + props?.employeeInfo?.imgPath}
                imageOf={props?.employeeInfo?.name}
              />
            )}
            {props?.employeeInfo?.name && <h4>{props?.employeeInfo?.name}</h4>}
            {props?.employeeInfo?.designationName && (
              <p>Designation: {props?.employeeInfo?.designationName}</p>
            )}
            {props?.employeeInfo?.teamName && (
              <p>Team: {props.employeeInfo.teamName}</p>
            )}
            {props?.employeeInfo?.email && (
              <p>Email: {props?.employeeInfo?.email}</p>
            )}
            {props?.employeeInfo?.contactNumber && (
              <p>Phone: {props?.employeeInfo?.contactNumber}</p>
            )}
            {props?.employeeInfo?.empSupervisorName && (
              <p>Supervisor: {props?.employeeInfo?.empSupervisorName}</p>
            )}
            {props.editLink && (
              <Link to={props?.editLink} className='btn'>
                View Details
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
