import React from 'react';
import Card from './Card';
// data table import
import DataTable from '../components/DataTableBase';

const TodaysLeave = (props) => {
  const todaysLeave = props.todaysLeave;
  const columns = [
    {
      name: 'Name',
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: 'Leave For',
      selector: (row) => row?.count,
    },
    {
      name: 'Start Date',
      selector: (row) => row?.startDate.split('T')[0],
    },
    {
      name: 'End Date',
      selector: (row) => row?.endDate.split('T')[0],
    },
    {
      name: 'Leave Type',
      selector: (row) => row?.type,
      sortable: true,
    },
  ];
  // expandable row configuration
  const ExpandedComponent = ({ data }) => (
    <Card key={data._id} todaysLeave={data} />
  );
  if (todaysLeave.length === 0) {
    return <h2 className='mt-30'>No one is on Leave Today.</h2>;
  }
  return (
    <>
      {todaysLeave.length > 0 && (
        <div className='pendingLeave'>
          <h2>Employee on Leave Today</h2>
          <DataTable
            columns={columns}
            data={todaysLeave}
            persistTableHead
            highlightOnHover
            expandableRows
            expandableRowsComponent={ExpandedComponent}
          />
        </div>
      )}
    </>
  );
};

export default TodaysLeave;
