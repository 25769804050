import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useNavigate } from 'react-router-dom';

const Failure = () => {
  sessionStorage.setItem('failed', 'Sorry, Invalid email');
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const onCloseModal = () => {
    setOpen(false);
    navigate('/');
  };

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      classNames={{
        modal: 'customModal',
      }}
      animationDuration={800}
    >
      <p>Please Contact with HR for Login.</p>
    </Modal>
  );
};

export default Failure;
