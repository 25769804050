import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../api';
import BackButton from '../components/BackButton';
import TextInput from '../components/forms/TextInput';
import { useAuth } from '../context/AuthContext';
// Modal files include
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import SelectInput from '../components/forms/SelectInput';

function AddInvoice() {
  const { tokenValue, currentUser } = useAuth();
  const navigate = useNavigate();
  const [msg, setMsg] = useState('');
  // modal configuration data
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    navigate('/invoice');
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const formData = new FormData();

    for (const key in data) {
      if (key !== 'imageName') {
        formData.append(key, data[key]);
      } else {
        formData.append(key, data[key][0]);
      }
    }
    fetch(`${apiUrl}/invoice/create`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${tokenValue}`,
        'Allow-Control-Allow-Origin': '*',
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setMsg(data.msg || data.error);
        onOpenModal();
      })
      .catch((error) => {
        setMsg(error);
        onOpenModal();
      });
  };
  return (
    <main>
      <div className="container">
        <h1>Add a New Invoice</h1>
        <BackButton />
        <div className="book__form">
          <form
            method="post"
            className="form leaveForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="hidden"
              name="createdBy"
              defaultValue={currentUser._id}
              {...register('createdBy')}
            />
            <TextInput
              label="Invoice Title"
              name="title"
              id="title"
              type="text"
              register={register}
              rules={{
                required: '*field is required',
              }}
              errors={errors}
            />
            <TextInput
              label="Invoice Amount"
              name="amount"
              id="amount"
              type="number"
              register={register}
              rules={{
                required: '*field is required',
              }}
              errors={errors}
            />
            <SelectInput
              label="Cash In / Cash Out"
              name="cashType"
              id="cashType"
              options={[
                { name: 'Cash Out', id: 'Cash Out' },
                { name: 'Cash In', id: 'Cash In' },
              ]}
              register={register}
            />
            <TextInput
              label="Invoice Image"
              name="imageName"
              id="imageName"
              type="file"
              register={register}
            />
            <div className="form-item">
              <input
                className="btn btn-primary"
                type="submit"
                name="submit"
                value="Create Invoice"
              />
            </div>
          </form>
          <Modal
            open={open}
            onClose={onCloseModal}
            center
            classNames={{
              modal: 'customModal',
            }}
            animationDuration={800}
          >
            <p>{msg}</p>
          </Modal>
        </div>
      </div>
    </main>
  );
}

export default AddInvoice;
