import React, { useEffect, useState } from 'react';
// react hook form include
import { useForm } from 'react-hook-form';
// useParams from react router dom
import { useNavigate, useParams, useLocation } from 'react-router-dom';
// get api configuration
import { apiClient, apiUrl } from '../api';
import { useAuth } from '../context/AuthContext';
// API get include
import useFetch from '../hooks/useFetch';
// form input include
import SelectInput from './forms/SelectInput';
import TextAreaInput from './forms/TextAreaInput';
import TextInput from './forms/TextInput';
// Modal files include
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

function EditUser() {
  // get u=URL param
  const { slug } = useParams();
  const navigate = useNavigate();
  // get token value
  const { tokenValue } = useAuth();
  // get data from API
  const { data: singleData } = useFetch(`/emp/${slug}`);
  const { data: teamData } = useFetch('/team');
  const { data: designationData } = useFetch('/des');
  const { data: employees, error, loading } = useFetch(`/emp`);
  // declare useState variables
  const [team, setTeam] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [msg, setMsg] = useState('');
  const [count, setCount] = useState(false);

  // modal configuration data
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    navigate(-1);
  };
  // get supervisor list
  const supervisorList = employees?.filter(
    (supervisor) =>
      supervisor.userRole === 'supervisor' && supervisor.isActive === 'Active'
  );
  // get active Teams
  const activeTeams = team.filter((data) => data.isActive === 'Active');
  // get active Designation
  const activeDesignations = designation.filter(
    (data) => data.isActive === 'Active'
  );
  // register react hook form
  const { register, handleSubmit, reset } = useForm({
    defaultValues: singleData,
  });
  // set the data on useEffect
  useEffect(() => {
    setTeam(teamData);
    setDesignation(designationData);
    reset(singleData);
  }, [designationData, reset, singleData, teamData]);
  // form submit function
  const onSubmit = (data) => {
    const formData = new FormData();
    for (const key in data) {
      if (key !== 'nid' && key !== 'image' && key !== 'tin') {
        formData.append(key, data[key]);
      } else {
        formData.append(key, data[key][0]);
      }
    }
    setCount(!count);
    apiClient
      .put('/emp/update', formData, {
        headers: {
          authorization: `Bearer ${tokenValue}`,
        },
      })
      .then((res) => {
        setMsg(res.data.msg);
        onOpenModal();
      })
      .catch((err) => {
        setMsg(err);
        onOpenModal();
      });
  };
  // set user role
  const userRole = [
    {
      _id: 'admin',
      name: 'Admin',
    },
    {
      _id: 'supervisor',
      name: 'Supervisor',
    },
    {
      _id: 'employee',
      name: 'Employee',
    },
  ];
  // set blood groups
  const bloodGroup = [
    {
      _id: 'A+',
      name: 'A+',
    },
    {
      _id: 'A-',
      name: 'A-',
    },
    {
      _id: 'B+',
      name: 'B+',
    },
    {
      _id: 'B-',
      name: 'B-',
    },
    {
      _id: 'O+',
      name: 'O+',
    },
    {
      _id: 'O-',
      name: 'O-',
    },
    {
      _id: 'AB+',
      name: 'AB+',
    },
    {
      _id: 'AB-',
      name: 'AB-',
    },
  ];
  const { state } = useLocation();
  const currentPage = state || {};
  function handleClick() {
    navigate(`/employee-management?page=${currentPage}`);
  }

  return (
    <main>
      {loading && <h6>Loading emp data....</h6>}
      {error && <h6>{error}</h6>}
      {!loading && !error && (
        <div className="container">
          <div className="empNameImg">
            <h1>Details of {singleData?.name}</h1>
            <div className="profileImg">
              <img
                src={apiUrl + '/' + singleData?.imgPath}
                alt={'Image of' + singleData?.name}
              />
            </div>
          </div>

          <button className="btn backBtn" onClick={handleClick}>
            Back
          </button>
          <div className="book__form">
            <form
              method="post"
              className="form empForm"
              onSubmit={handleSubmit(onSubmit)}
            >
              <TextInput
                label="Full Name"
                name="name"
                id="name"
                type="text"
                register={register}
                required
              />
              <TextInput
                label="Phone Number"
                name="contactNumber"
                id="contactNumber"
                type="text"
                title="Only Number and 11 character Length"
                pattern="[0-9]{0}[0-9]{11}"
                register={register}
              />

              <TextInput
                label="Email Address"
                name="email"
                id="email"
                type="email"
                register={register}
              />

              <SelectInput
                label="Team"
                name="teamId"
                id="teamId"
                options={activeTeams}
                register={register}
                required
              />

              <SelectInput
                label="Designation"
                name="designationId"
                id="designationId"
                options={activeDesignations}
                register={register}
                required
              />

              <SelectInput
                label="Supervisor"
                name="supervisorId"
                id="supervisorId"
                options={supervisorList}
                register={register}
              />

              <TextInput
                label="NID"
                name="nationalIdNumber"
                id="nationalIdNumber"
                type="number"
                register={register}
              />
              <TextInput
                label="NID File"
                name="nid"
                id="nid"
                type="file"
                register={register}
              />
              <TextInput
                label="TIN File"
                name="tin"
                id="tin"
                type="file"
                register={register}
              />
              <SelectInput
                label="Blood Group"
                name="bloodGroup"
                id="bloodGroup"
                options={bloodGroup}
                register={register}
              />
              <TextAreaInput
                label="Present Address"
                name="presentAddress"
                id="presentAddress"
                register={register}
              />
              <TextAreaInput
                label="Permanent Address"
                name="permanentAddress"
                id="permanentAddress"
                register={register}
              />
              <TextInput
                label="Emergency Contact"
                name="emergencyContactDetails"
                id="emergencyContactDetails"
                title="Only Number and 11 character Length"
                pattern="[0-9]{0}[0-9]{11}"
                register={register}
              />
              <TextInput
                label="Join Date"
                name="joiningDate"
                id="joiningDate"
                type="date"
                register={register}
              />
              <TextInput
                label="Profile Pic"
                name="image"
                id="image"
                type="file"
                register={register}
              />
              <TextInput
                label="DOB"
                name="dob"
                id="dob"
                type="date"
                register={register}
              />
              <TextInput
                label="Date of Termination"
                name="dateOfTermination"
                id="dateOfTermination"
                type="date"
                register={register}
              />
              <TextInput
                label="Total Leave"
                name="totalLeave"
                id="totalLeave"
                type="number"
                defaultValue={0}
                register={register}
              />
              <TextInput
                label="Sick Leave"
                name="sickLeave"
                id="sickLeave"
                type="number"
                defaultValue={0}
                register={register}
              />
              <TextInput
                label="Casual Leave"
                name="casualLeave"
                id="casualLeave"
                type="number"
                defaultValue={0}
                register={register}
              />
              <TextInput
                label="Family Leave"
                name="familyLeave"
                id="familyLeave"
                type="number"
                defaultValue={0}
                register={register}
              />
              <TextInput
                label="Parenthood Leave"
                name="parenthoodLeave"
                id="parenthoodLeave"
                type="number"
                defaultValue={0}
                register={register}
              />
              <TextInput
                label="Marital Leave"
                name="maritalLeave"
                id="maritalLeave"
                type="number"
                defaultValue={0}
                register={register}
              />
              <TextInput
                label="Bonus Leave"
                name="bonusLeave"
                id="bonusLeave"
                type="number"
                defaultValue={0}
                register={register}
              />
              <TextInput
                label="Holiday Leave"
                name="holidayLeave"
                id="holidayLeave"
                type="number"
                defaultValue={0}
                register={register}
                readOnly={true}
              />
              <TextInput
                label="Available Leave"
                name="availableLeave"
                id="availableLeave"
                type="number"
                defaultValue={0}
                register={register}
              />
              <TextInput
                label="Consumed Leave"
                name="consumedLeave"
                id="consumedLeave"
                type="number"
                defaultValue={0}
                register={register}
              />
              <SelectInput
                label="Employee Status"
                name="isActive"
                id="isActive"
                options={[
                  { name: 'Active', id: 1 },
                  { name: 'Inactive', id: 0 },
                ]}
                register={register}
                required
              />
              <SelectInput
                label="User Role"
                name="userRole"
                id="id"
                options={userRole}
                register={register}
                required
              />
              <div className="form-item">
                <input
                  className="btn btn-primary"
                  type="submit"
                  name="submit"
                  value="Save"
                />
              </div>
            </form>
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              classNames={{
                modal: 'customModal',
              }}
              animationDuration={800}
            >
              <p>{msg}</p>
            </Modal>
          </div>
        </div>
      )}
    </main>
  );
}

export default EditUser;
