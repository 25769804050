import Nav from './components/Nav';
import AuthProvider from './context/AuthContext';
import AllRoutes from './routes';

function App() {
    return (
        <AuthProvider>
            <Nav />
            <AllRoutes />
        </AuthProvider>
    );
}

export default App;
