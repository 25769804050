import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';

import { ApiCall } from '../api';
import BackButton from '../components/BackButton';
import { useAuth } from '../context/AuthContext';

const ApplyForHolidayLeave = () => {
  const { tokenValue, currentUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const navigate = useNavigate();
  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
    navigate('/dashboard');
  };

  const onSubmit = async (data) => {
    document.getElementById('overlay').style.display = 'block';
    document.getElementById('loading-page').style.display = 'block';
    try {
      const url = '/holiday/leave/applications/add';
      const result = await ApiCall(url, 'post', tokenValue, data);
      document.getElementById('overlay').style.display = 'none';
      document.getElementById('loading-page').style.display = 'none';
      setMessage(result.data.message || result.error);
      onOpenModal();
    } catch (error) {
      document.getElementById('overlay').style.display = 'none';
      document.getElementById('loading-page').style.display = 'none';
      setMessage(error.message);
      onOpenModal();
    }
  };
  const startDateValue = watch('startDate');

  return (
    <main>
      <div id="loading-page" style={{ display: 'none' }}>
        Loading...
      </div>
      <div className="container">
        <h1>Request to include holiday leave</h1>
        <p className="highlight-text">
          <span role="img" aria-label="star">
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </span>
          If you are working during the holiday, please submit your application.{' '}
          <span role="img" aria-label="star">
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
            <i className="far fa-star"></i>
          </span>
        </p>
        <BackButton />
        <div className="overlay" id="overlay"></div>
        <div className="book__form">
          <form
            method="post"
            className="form leaveForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type={'hidden'}
              defaultValue={currentUser._id}
              {...register('employeeId')}
            />
            <div className="form-item">
              <label htmlFor="startDate"> Date Form </label>
              <span className="alert-color">*</span>
              <input
                id="startDate"
                className="field_sm"
                type="date"
                {...register('startDate', {
                  required: 'start date is required',
                })}
              />
              {errors.startDate && (
                <span className="error">{errors.startDate.message}</span>
              )}
            </div>
            <div className="form-item">
              <label htmlFor="endDate"> Date To </label>
              <span className="alert-color">*</span>
              <input
                id="endDate"
                className="field_sm"
                type="date"
                {...register('endDate', {
                  required: 'end date is required',
                  validate: (value) =>
                    value >= startDateValue || `*The end date can't be small`,
                })}
              />
              {errors.endDate && (
                <span className="error">{errors.endDate.message}</span>
              )}
            </div>
            <div className="form-item">
              <label htmlFor="description"> Description </label>
              <span className="alert-color">*</span>
              <textarea
                {...register('description', {
                  required: 'description is required',
                })}
              />
              {errors.description && (
                <span className="error">{errors.description.message}</span>
              )}
            </div>
            <div className="form-item">
              <input
                className="btn btn-primary"
                type="submit"
                name="submit"
                value="Apply"
              />
            </div>
          </form>
          <Modal
            open={open}
            onClose={onCloseModal}
            center
            classNames={{
              modal: 'customModal',
            }}
            animationDuration={800}
          >
            <p>{message}</p>
          </Modal>
        </div>
      </div>
    </main>
  );
};

export default ApplyForHolidayLeave;
