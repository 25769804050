import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useLocation } from 'react-router-dom';
import { apiClient, apiUrl } from '../api';
import BackButton from '../components/BackButton';
import SelectInput from '../components/forms/SelectInput';
import TextAreaInput from '../components/forms/TextAreaInput';
import { useAuth } from '../context/AuthContext';
import useFetch from '../hooks/useFetch';
import { useNavigate } from 'react-router-dom';

function TicketReview() {
  const location = useLocation();
  const navigate = useNavigate();
  const ticketInfo = location.state;
  const { tokenValue, currentUser } = useAuth();
  const userRole = currentUser?.userRole;
  const id = ticketInfo._id;
  // get ticket data from API
  const { data: singleTicketInfo } = useFetch(`/ticket/${id}`);
  const [msg, setMsg] = useState('');
  // modal configuration data
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    navigate(-1);
  };
  // use hook form register
  const { register, handleSubmit, reset } = useForm({
    defaultValues: singleTicketInfo,
  });
  useEffect(() => {
    reset(singleTicketInfo);
  }, [reset, singleTicketInfo]);
  // form data process function
  const onSubmit = async (data) => {
    await apiClient
      .put(`/ticket/${id}`, data, {
        headers: {
          authorization: `Bearer ${tokenValue}`,
        },
      })
      .then((res) => {
        setMsg(res.data.msg);
        if (res?.data?.msg === 'added successfully') {
          reset();
        }
        onOpenModal();
      })
      .catch((err) => {
        setMsg(err);
        onOpenModal();
      });
  };
  return (
    <main>
      <div className="container">
        <div className="empNameImg">
          <h1>Review Ticket of {ticketInfo?.employeeInfo?.name}</h1>
          <div className="profileImg">
            <img
              src={apiUrl + '/' + ticketInfo?.employeeInfo?.imgPath}
              alt={'Image of' + ticketInfo?.employeeInfo?.name}
            />
          </div>
        </div>
        <BackButton />
        <div className="leaveInfo">
          <div className="leaveData">
            <div className="ticketInfo-item">
              <h3>Title:</h3>
              <p>{ticketInfo?.title}</p>
            </div>
            <div className="ticketInfo-item">
              <h3>Description:</h3>
              <p>{ticketInfo?.description}</p>
            </div>
            <div className="ticketInfo-item">
              <h3>Created at:</h3>
              <p>{ticketInfo?.createDate.split('T')[0]}</p>
            </div>
            {ticketInfo?.adminSpecialNote && (
              <div className="ticketInfo-item">
                <h3>Admin Special Note:</h3>
                <p>{ticketInfo?.adminSpecialNote}</p>
              </div>
            )}
            {userRole?.toLowerCase() === 'admin' && ticketInfo?.supervisorId && (
              <>
                <div className="ticketInfo-item">
                  <h3>Supervisor Approval Status:</h3>
                  <p>{ticketInfo?.supervisorApproveStatus}</p>
                </div>
                {ticketInfo?.supervisorNote && (
                  <div className="ticketInfo-item">
                    <h3>Supervisor Note:</h3>
                    <p>{ticketInfo?.supervisorNote}</p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="book__form">
            <form
              method="post"
              className="form teamForm"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                type="hidden"
                name="userRole"
                defaultValue={userRole}
                {...register('userRole')}
              />
              {userRole?.toLowerCase() === 'admin' && (
                <>
                  <TextAreaInput
                    label="Special Note about the ticket"
                    name="adminSpecialNote"
                    id="adminNoteOnTicket"
                    register={register}
                  />
                  <SelectInput
                    label="Ticket Status"
                    name="adminApproveStatus"
                    id="adminApproveStatus"
                    options={[
                      { name: 'Approved', id: 'approved' },
                      { name: 'Reject', id: 'reject' },
                    ]}
                    register={register}
                  />
                  <TextAreaInput
                    label="Comments"
                    name="adminNote"
                    id="adminNote"
                    register={register}
                  />
                </>
              )}
              {userRole?.toLowerCase() === 'supervisor' && (
                <>
                  <SelectInput
                    label="Ticket Status"
                    name="supervisorApproveStatus"
                    id="supervisorApproveStatus"
                    options={[
                      { name: 'Approved', id: 'approved' },
                      { name: 'Reject', id: 'reject' },
                    ]}
                    register={register}
                  />
                  <TextAreaInput
                    label="Comments"
                    name="supervisorNote"
                    id="supervisorNote"
                    register={register}
                  />
                </>
              )}
              <div className="form-item">
                <input
                  className="btn btn-primary"
                  type="submit"
                  name="submit"
                  value="Save"
                />
              </div>
            </form>
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              classNames={{
                modal: 'customModal',
              }}
              animationDuration={800}
            >
              <p>{msg}</p>
            </Modal>
          </div>
        </div>
      </div>
    </main>
  );
}

export default TicketReview;
