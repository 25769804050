import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ApiCall, apiUrl } from '../api';
import { useAuth } from '../context/AuthContext';
import DataTable from './DataTableBase';
import { FilterComponent } from './FilterComponent';

function DisplayDataTable(props) {
  const { tokenValue } = useAuth();
  const location = useLocation();
  const [applicationData, setApplicationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(
    Number(new URLSearchParams(location.search).get('page')) || 1
  );
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const url = `holiday/leave/applications${
    props.employeeId ? '/emp/' + props.employeeId : ''
  }${
    props.status ? '/' + props.status : ''
  }?page=${currentPage}&per_page=${perPage}&search=${filterText}&delay=1`;
  useEffect(() => {
    const fetchApplicationData = async () => {
      try {
        setIsLoading(true);
        const result = await ApiCall(url, 'get', tokenValue);
        setApplicationData(result.data.data?.applicationsData);
        setTotalRows(result?.data.data?.totalApplication);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchApplicationData();
  }, [tokenValue, url]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true);
    setPerPage(newPerPage);
    setCurrentPage(page);
    const response = await ApiCall(url, 'get', tokenValue);
    setApplicationData(response.data?.applicationsData);
    setTotalRows(response?.data?.totalApplication);
    setIsLoading(false);
  };
  function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  const columns = [
    {
      name: 'Name',
      selector: (row) => (
        <div className="tableImg">
          {row?.employeeInfo?.imgPath && (
            <img
              src={apiUrl + '/' + row?.employeeInfo?.imgPath}
              alt={'Image of ' + row?.employeeInfo?.name}
            />
          )}
          {row?.employeeInfo?.name && <p>{row?.employeeInfo?.name}</p>}
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Apply For(Day)',
      selector: (row) => row?.count,
      sortable: true,
      cell: (row) => <div style={{ paddingLeft: '20px' }}>{row?.count}</div>,
    },
    {
      name: 'Status',
      selector: (row) => capitalizeFirstLetter(row?.adminApprovalStatus),
      sortable: true,
    },
    {
      name: 'Start Date',
      selector: (row) => row?.startDate?.split('T')[0],
      sortable: true,
    },
    {
      name: 'End Date',
      selector: (row) => row?.endDate?.split('T')[0],
      sortable: true,
    },
    props.action
      ? {
          name: 'Action',
          selector: (row) => {
            const shouldRenderAction =
              row?.adminApprovalStatus?.toLowerCase() === 'pending'
                ? true
                : false;
            // Render the link only if the condition is true
            return shouldRenderAction ? (
              <Link
                to={`/application-review/${row._id}`}
                state={row}
                className="btn"
              >
                Review
              </Link>
            ) : null; // Return null if you don't want to render anything in the cell
          },
        }
      : null,
  ];

  // setup filter for search
  const filteredApplications = applicationData?.filter(
    (item) =>
      item.employeeInfo?.name &&
      item.employeeInfo?.name?.toLowerCase().includes(filterText?.toLowerCase())
  );
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  // expandable row configuration
  const ExpandedComponent = ({ data }) => (
    <div className="leaveReason">
      {data?.description && <p>{`Description: ${data?.description}`}</p>}
      {props?.status?.toLowerCase() !== 'pending' && data.note && (
        <p>{`Admin Note: ${data?.note}`}</p>
      )}
    </div>
  );
  return (
    <div className="pendingLeave">
      <h2>{props.title}</h2>
      <DataTable
        columns={columns.filter(Boolean)}
        data={filteredApplications}
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={perPage}
        paginationComponentOptions={{
          rowsPerPageText: 'Rows per page:',
          rangeSeparatorText: 'of',
          noRowsPerPage: false,
          selectAllRowsItem: true,
          selectPerPageItem: false,
        }}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationDefaultPage={currentPage}
        progressPending={isLoading}
        persistTableHead
        highlightOnHover
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
      />
    </div>
  );
}

export default DisplayDataTable;
