import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import useFetch from '../hooks/useFetch';
import { apiClient, apiUrl } from '../api';

const Profile = () => {
  const { currentUser, tokenValue } = useAuth();
  const [leaveSummary, setLeaveSummary] = useState();
  // get all employees
  const {
    data: employeeData,
    loading,
    error,
  } = useFetch(`/emp/${currentUser?._id}`);
  useEffect(() => {
    apiClient
      .get(
        `/leave/individualSummary/${
          currentUser?._id
        }/${new Date().getFullYear()}`,
        {
          headers: {
            authorization: `Bearer ${tokenValue}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setLeaveSummary(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentUser, tokenValue]);
  // get designation name
  const { data: desData } = useFetch(`/des/`);
  // get team name
  const { data: teamData } = useFetch(`/team/`);

  // setup employee data with team, designation and supervisor data
  let teamInfo = teamData.find(
    (itmInner) => itmInner._id === employeeData?.teamId
  );
  let designationInfo = desData.find(
    (itmInner) => itmInner._id === employeeData?.designationId
  );
  employeeData.designationName = designationInfo?.name;
  employeeData.teamName = teamInfo?.name;

  return (
    <main>
      <div className="container">
        {loading && <h6>Loading emp data....</h6>}
        {error && <h6>{error}</h6>}
        {/* <BackButton /> */}
        {!loading && !error && (
          <>
            <div className="profileInfo shadow-sm">
              <div className="profilePic">
                <img
                  src={apiUrl + '/' + employeeData?.imgPath}
                  alt={`profile of ${employeeData?.name}`}
                />
              </div>
              <div className="profileIfo-item">
                <h3>
                  {employeeData?.name}, {employeeData?.designationName}
                </h3>
              </div>
              <div className="profileIfo-item">
                <h3>Team: {employeeData?.teamName}</h3>
              </div>
              {employeeData?.supervisorName && (
                <div className="profileIfo-item">
                  <h3>Supervisor: {employeeData?.supervisorName}</h3>
                </div>
              )}
              <div className="profileIfo-item">
                <h3>Email: {employeeData?.email}</h3>
              </div>
              <div className="profileIfo-item">
                <h3>Phone: {employeeData?.contactNumber}</h3>
              </div>
              <div className="profileIfo-item">
                <h3>Blood Group: {employeeData?.bloodGroup}</h3>
              </div>
              <div className="profileIfo-item">
                <h3>Total Leave: {employeeData?.totalLeave}</h3>
              </div>
              <div className="profileIfo-item">
                <h3>Available Leave: {employeeData?.availableLeave}</h3>
              </div>
              <div className="profileIfo-item">
                <h3>
                  Consumed Leave:{' '}
                  {employeeData?.consumedLeave
                    ? employeeData?.consumedLeave
                    : 0}
                </h3>
              </div>
              {leaveSummary && (
                <>
                  <div className="profileIfo-item">
                    <h3>Casual Leave Usage: {leaveSummary?.casual}</h3>
                  </div>
                  <div className="profileIfo-item">
                    <h3>Family Leave Usage: {leaveSummary?.family}</h3>
                  </div>
                  <div className="profileIfo-item">
                    <h3>Sick Leave Usage: {leaveSummary?.sick}</h3>
                  </div>
                  {leaveSummary?.special ? (
                    <div className="profileIfo-item">
                    <h3>Special Leave Usage: {leaveSummary?.special}</h3>
                  </div>
                  ) : ''}
                  {leaveSummary?.parenthood ? (
                    <div className="profileIfo-item">
                    <h3>Parenthood Leave Usage: {leaveSummary?.parenthood}</h3>
                  </div>
                  ) : ''}
                  {leaveSummary?.marital ? (
                    <div className="profileIfo-item">
                    <h3>Marital Leave Usage: {leaveSummary?.marital}</h3>
                  </div>
                  ) : ''}
                  {leaveSummary?.bonus ? (
                    <div className="profileIfo-item">
                    <h3>Bonus Leave Usage: {leaveSummary?.bonus}</h3>
                  </div>
                  ) : ''}
                  {leaveSummary?.holiday? (
                    <div className="profileIfo-item">
                    <h3>Holiday Leave Usage: {leaveSummary?.holiday}</h3>
                  </div>
                  ) : ''}
                  <div className="profileIfo-item">
                    <h3>Unpaid Leave Usage: {leaveSummary?.unpaid}</h3>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default Profile;
