import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react/cjs/react.development';
import { useAuth } from '../context/AuthContext';
import logo from '../resources/braincraft-logo.png';

const Nav = () => {
  const { isLoggedIn, currentUser, logOut } = useAuth();
  const [isActive, setIsActive] = useState(false);
  const toggleClass = () => {
    setIsActive(!isActive);
  };

  return (
    <header id="header">
      <nav className="navbar">
        <span className="navbar-toggle" id="js-navbar-toggle" onClick={toggleClass}>
          <i className="fas fa-bars"></i>
        </span>
        <a href="/" className="logo">
          <img src={logo} alt="Brain Craft LTD. logo" />
        </a>
        {isLoggedIn && (
          <ul id="js-menu" className={isActive ? 'main-nav active' : 'main-nav'}>
            <li>
              <Link to="/dashboard" className="nav-links homeDashboard" onClick={toggleClass}>
                Home
              </Link>
            </li>
            {currentUser?.userRole === 'admin' && (
              <>
                <li>
                  <Link
                    to="/team-management"
                    className="nav-links teamManagement"
                    onClick={toggleClass}
                  >
                    Team
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/invoice"
                    className="nav-links invoiceManagement"
                    onClick={toggleClass}
                  >
                    Invoice
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="/employee-management"
                    className="nav-links employeeManagement"
                    onClick={toggleClass}
                  >
                    Employees
                  </Link>
                </li>
                <li>
                  <Link
                    to="/leave-management"
                    className="nav-links LeaveManagement"
                    onClick={toggleClass}
                  >
                    Leave Management
                  </Link>
                </li>
              </>
            )}
            {currentUser?.userRole === 'supervisor' && (
              <li>
                <Link to="/team-member" className="nav-links teamMember" onClick={toggleClass}>
                  Team
                </Link>
              </li>
            )}
            <li>
              <Link to="/profile" className="nav-links employeeProfile" onClick={toggleClass}>
                Profile
              </Link>
            </li>
            <li>
              <Link
                to="/leave-application"
                className="nav-links leaveApplication"
                onClick={toggleClass}
              >
                Leave Application
              </Link>
            </li>
            <li>
              <Link
                to="/ticket-management"
                className="nav-links ticketManagement"
                onClick={toggleClass}
              >
                Ticket
              </Link>
            </li>
            <li>
              <a
                href="http://docs.google.com/spreadsheets/d/1T69yV4pZipT7p7W11y18kENRC6Q13O1Cj1Ux259ymLQ/edit?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-links ticketManagement"
              >
                Meeting
              </a>
            </li>
            <li>
              <button className="nav-links" onClick={logOut}>
                Logout
              </button>
            </li>
          </ul>
        )}
      </nav>
    </header>
  );
};

export default Nav;
