import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { ApiCall, apiUrl } from '../api';
import BackButton from '../components/BackButton';
import SelectInput from '../components/forms/SelectInput';
import TextAreaInput from '../components/forms/TextAreaInput';
import { useAuth } from '../context/AuthContext';

function ApplicationReview() {
  const location = useLocation();
  const navigate = useNavigate();
  const applicationInfo = location.state;
  const { tokenValue, currentUser } = useAuth();
  const userRole = currentUser?.userRole;
  const id = applicationInfo._id;
  const [msg, setMsg] = useState('');
  // modal configuration data
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    navigate(-1);
  };
  // use hook form register
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    try {
      document.getElementById('overlay').style.display = 'block';
      document.getElementById('loading-page').style.display = 'block';
      const url = `/holiday/leave/applications/${id}`;
      const result = await ApiCall(url, 'patch', tokenValue, data);
      document.getElementById('overlay').style.display = 'none';
      document.getElementById('loading-page').style.display = 'none';
      setMsg(result.data.message || result.error);
      onOpenModal();
    } catch (error) {
      document.getElementById('overlay').style.display = 'none';
      document.getElementById('loading-page').style.display = 'none';
      setMsg(error.message);
      onOpenModal();
    }
  };
  return (
    <main>
      <div className="container">
        <div id="loading-page" style={{ display: 'none' }}>
          Loading...
        </div>
        <div className="overlay" id="overlay"></div>
        <div className="empNameImg">
          <h1>
            Review Holiday Leave application of{' '}
            {applicationInfo?.employeeInfo?.name}
          </h1>
          <div className="profileImg">
            <img
              src={apiUrl + '/' + applicationInfo?.employeeInfo?.imgPath}
              alt={'Image of' + applicationInfo?.employeeInfo?.name}
            />
          </div>
        </div>
        <BackButton />
        <div className="leaveInfo">
          <div className="leaveData">
            <div className="ticketInfo-item">
              <h3>Apply for(Day):</h3>
              <p>{applicationInfo?.count}</p>
            </div>
            <div className="ticketInfo-item">
              <h3>Description:</h3>
              <p>{applicationInfo?.description}</p>
            </div>
            <div className="ticketInfo-item">
              <h3>Start Date:</h3>
              <p>{applicationInfo?.startDate.split('T')[0]}</p>
            </div>
            <div className="ticketInfo-item">
              <h3>End Date:</h3>
              <p>{applicationInfo?.endDate.split('T')[0]}</p>
            </div>
          </div>
          <div className="book__form">
            <form
              method="post"
              className="form teamForm"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                type="hidden"
                name="userRole"
                defaultValue={userRole}
                {...register('userRole')}
              />
              {userRole?.toLowerCase() === 'admin' && (
                <>
                  <SelectInput
                    label="Application Status"
                    name="status"
                    id="status"
                    options={[
                      { name: 'Approved', id: 'approved' },
                      { name: 'Reject', id: 'reject' },
                    ]}
                    register={register}
                  />
                  <TextAreaInput
                    label="Comments"
                    name="note"
                    id="note"
                    register={register}
                  />
                </>
              )}
              <div className="form-item">
                <input
                  className="btn btn-primary"
                  type="submit"
                  name="submit"
                  value="Save"
                />
              </div>
            </form>
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              classNames={{
                modal: 'customModal',
              }}
              animationDuration={800}
            >
              <p>{msg}</p>
            </Modal>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ApplicationReview;
