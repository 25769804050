import React, { useMemo, useState } from 'react';

// data table import
import DataTable from '../components/DataTableBase';
import { FilterComponent } from './FilterComponent';

function ApprovedLeave(props) {
  const columns = [
    {
      name: 'Name',
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: 'Leave For',
      selector: (row) => row?.count,
    },
    {
      name: 'Start Date',
      selector: (row) => row?.startDate.split('T')[0],
      sortable: true,
    },
    {
      name: 'End Date',
      selector: (row) => row?.endDate.split('T')[0],
      sortable: true,
    },
    {
      name: 'Leave Type',
      selector: (row) => row?.type,
      sortable: true,
    },
  ];
  // setup filter for search
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredApprovedLeave = props?.approvedLeave.filter(
    (item) =>
      (item.employeeInfo?.name &&
        item.employeeInfo?.name
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item.type &&
        item.type.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.title &&
        item.title.toLowerCase().includes(filterText.toLowerCase()))
  );
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  // expandable row configuration
  const ExpandedComponent = ({ data }) => (
    <div className='leaveReason'>
      <p>{`Reason: ${data?.reason}`}</p>
      {data?.adminNote && <p>{`Admin Note: ${data?.adminNote}`}</p>}
      {data?.leavePenalty ? (
        <p>{`Took leave between holidays, that's why ${data?.leavePenalty} penalty given`}</p>
      ) : (
        ''
      )}
    </div>
  );

  if (props?.approvedLeave.length === 0) {
    return <h2 className='mt-30'>No Approved Leave applications</h2>;
  }
  return (
    <div className='pendingLeave'>
      <h2>Approved Leave applications</h2>
      <DataTable
        columns={columns}
        data={filteredApprovedLeave}
        persistTableHead
        highlightOnHover
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
      />
    </div>
  );
}

export default ApprovedLeave;
