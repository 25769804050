import React from 'react'

function CardHeroImg(props) {
  return (
    <div className="profileImg">
    <img
      src={
        props.imageLink
      }
      alt={`profile of ${props.imageOf}`}
    />
  </div>
  )
}

export default CardHeroImg