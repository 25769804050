import React from 'react';
import DataTable from 'react-data-table-component';

const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };
//  custom styling of the table.
const customStyles = {
  rows: {
    style: {
      minHeight: '70px', // override the row height
    },
  },
  table: {
    style: {
      border: '1px solid #EEEEEE', // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: '18px',
      fontWeight: '700',
    },
  },
  cells: {
    style: {
      fontSize: '16px',
    },
  },
  pagination: {
    style: {
      fontSize: '16px',
    },
  },
};
function DataTableBase(props) {
  return (
    <DataTable
      striped
      pagination
      selectableRowsComponentProps={selectProps}
      customStyles={customStyles}
      {...props}
    />
  );
}

export default DataTableBase;
