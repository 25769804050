import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiClient } from '../api';
import { useAuth } from '../context/AuthContext';

const Success = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { setCurrentUser } = useAuth();
  localStorage.setItem('__token__', token);

  useEffect(() => {
    apiClient
      .post(
        '/emp',
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCurrentUser(res?.data?.data);
        navigate('/dashboard');
      })
      .catch((err) => err);
  }, [navigate, setCurrentUser, token]);

  return <></>;
};

export default Success;
