import React from 'react';

import BackButton from '../components/BackButton';
import DisplayDataTable from '../components/DisplayDataTable';

function HolidayLeaveManagement(props) {
  return (
    <>
      {props.dashboardView ? (
        <main>
          <DisplayDataTable
            title={props.title}
            status={props.status}
            employeeId={props.employeeId}
            action={props.action}
          />
        </main>
      ) : (
        <div className="container">
          <main>
            <div className="headingWithBtn">
              <h1>Holiday Leave Applications</h1>
            </div>
            <BackButton />
            <DisplayDataTable
              title="Pending applications"
              status="pending"
              action={true}
            />
            <DisplayDataTable title="Approved applications" status="approved" />
            <DisplayDataTable title="Rejected applications" status="rejected" />
          </main>
        </div>
      )}
    </>
  );
}

export default HolidayLeaveManagement;
