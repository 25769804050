import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react/cjs/react.development';
import { apiClient, apiUrl } from '../api';
import BackButton from '../components/BackButton';
import SelectInput from '../components/forms/SelectInput';
import TextAreaInput from '../components/forms/TextAreaInput';
import { useAuth } from '../context/AuthContext';
// Modal files include
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

const LeaveAdjust = (props) => {
  const location = useLocation();
  const [msg, setMsg] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { tokenValue, currentUser } = useAuth();
  const leaveInfo = location.state;
  const applicationDate = {
    _id: leaveInfo._id,
  };
  // modal configuration data
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    navigate(-1);
  };
  // use hook form register
  const { register, handleSubmit, reset } = useForm();
  // form data process function
  const onSubmit = async (data) => {
    setSubmitting(true);
    await apiClient
      .put(
        '/leave/adjust',
        { ...applicationDate, ...data },
        {
          headers: {
            authorization: `Bearer ${tokenValue}`,
          },
        }
      )
      .then((res) => {
        setMsg(res.data.msg);
        if (res?.data?.msg === 'Leave status updated successfully.') {
          reset();
          setSubmitting(false);
        }
        onOpenModal();
      })
      .catch((err) => {
        setMsg(err);
        setSubmitting(false);
        onOpenModal();
      });
  };

  return (
    <main>
      <div className='container'>
        <div className='empNameImg'>
          <h1>Adjust Leave Application of {leaveInfo?.employeeInfo?.name}</h1>
          <div className='profileImg'>
            <img
              src={apiUrl + '/' + leaveInfo?.employeeInfo?.imgPath}
              alt={'Image of' + leaveInfo?.employeeInfo?.name}
            />
          </div>
        </div>
        <BackButton />
        <div className='leaveInfo'>
          <div className='leaveData'>
            <div className='leaveInfo-item'>
              <h3>Leave Type:</h3>
              <p>{leaveInfo?.type}</p>
            </div>
            <div className='leaveInfo-item'>
              <h3>Start Date:</h3>
              <p>{leaveInfo?.startDate.split('T')[0]}</p>
            </div>
            <div className='leaveInfo-item'>
              <h3>End Date:</h3>
              <p>{leaveInfo?.endDate.split('T')[0]}</p>
            </div>
            <div className='leaveInfo-item'>
              <h3>Leave for:</h3>
              <p>{leaveInfo?.count}</p>
            </div>
            <div className='leaveInfo-item'>
              <h3>Available Leave:</h3>
              <p>{leaveInfo?.employeeInfo?.availableLeave}</p>
            </div>
            <div className='leaveInfo-item'>
              <h3>Consumed Leave:</h3>
              <p>{leaveInfo?.employeeInfo?.consumedLeave}</p>
            </div>
            <div className='leaveInfo-item'>
              <h3>Sandwich leave:</h3>
              <p>{leaveInfo?.isSandwich ? 'Yes' : 'No'}</p>
            </div>
            <div className='leaveInfo-item'>
              <h3>Reason:</h3>
              <p>{leaveInfo?.reason}</p>
            </div>
            {leaveInfo?.leavePenalty? (
              <div className='leaveInfo-item'>
              <h3 className='leave-penalty'>Leave Penalty:</h3>
              <p>{leaveInfo?.leavePenalty}</p>
            </div>

            ): ""}
            {leaveInfo.adminNote? (
              <div className='leaveInfo-item'>
              <h3 className='leave-penalty'>Admin Note:</h3>
              <p>{leaveInfo?.adminNote}</p>
            </div>

            ): ""}
          </div>
          <div className='book__form'>
            <form
              method='post'
              className='form teamForm'
              onSubmit={handleSubmit(onSubmit)}
            >
              {currentUser.userRole?.toLowerCase() === 'admin' && (
                <>
                  <SelectInput
                    label='Leave Status'
                    name='currentStatus'
                    id='currentStatus'
                    defaultValue={leaveInfo.currentStatus}
                    options={[
                      { name: 'Approved', id: 'approved' },
                      { name: 'Reject', id: 'reject' },
                    ]}
                    register={register}
                  />
                </>
              )}
              <TextAreaInput
                label='Adjustment Note'
                name='adjustmentNote'
                id='adjustmentNote'
                register={register}
              />
              {currentUser.userRole?.toLowerCase() === 'admin' && (
                <div className='form-item'>
                  <input
                    className='btn btn-primary'
                    type='submit'
                    name='submit'
                    value='Save'
                    disabled={submitting} // Disable the button when submitting is true
                  />
                </div>
              )}
            </form>
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              classNames={{
                modal: 'customModal',
              }}
              animationDuration={800}
            >
              <p>{msg}</p>
            </Modal>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LeaveAdjust;
