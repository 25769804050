import React, { useMemo, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import useFetch from '../hooks/useFetch';
import { FilterComponent } from '../components/FilterComponent';
import DataTable from '../components/DataTableBase';
import { apiUrl } from '../api';

function TeamMember() {
  const { currentUser } = useAuth();
  const supervisorId = currentUser._id;
  // get employee data by supervisor
  const {
    data: employeeData,
    error,
    loading,
  } = useFetch(`/emp/supervisor/${supervisorId}`);
  // data table column name setup
  const columns = [
    {
      name: 'Name',
      selector: (row) => (
        <div className="tableImg">
          {row?.imgPath && (
            <img
              src={apiUrl + '/' + row?.imgPath}
              alt={'Image of ' + row?.name}
            />
          )}
          {row?.name && <p>{row?.name}</p>}
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
    },
    {
      name: 'Designation',
      selector: (row) => row.designationInfo.name,
      sortable: true,
    },
    {
      name: 'Available Leave',
      selector: (row) => row.availableLeave,
    },
    {
      name: 'Phone',
      selector: (row) => row.contactNumber,
    },
  ];
  // setup filter for search
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = employeeData.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase()))
  );
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  // setup filter for search ends
  const ExpandedComponent = ({ data }) => (
    <div className="leaveReason">
      <p>Team Name: {data.teamInfo.name}</p>
      <p>Email: {data.email}</p>
      <p>Designation: {data.designationInfo.name}</p>
    </div>
  );
  return (
    <main>
      <div className="container">
        {loading && <h6>Loading emp data....</h6>}
        {error && <h6>{error}</h6>}
        <h1>Team Members</h1>
        {!loading && (
          <DataTable
            columns={columns}
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            highlightOnHover
          />
        )}
      </div>
    </main>
  );
}

export default TeamMember;
