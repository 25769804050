import React, { useMemo, useState } from 'react';
import ApprovedLeave from './ApprovedLeave';
import Card from './Card';
// data table import
import DataTable from '../components/DataTableBase';
import { FilterComponent } from './FilterComponent';
import RejectedLeave from './RejectedLeave';

function EmpLeaveData(props) {
  const pendingLeave = props.pendingLeave;
  const rejectedLeave = props.rejectedLeave;
  const approvedLeave = props.approvedLeave;
  const columns = [
    {
      name: 'Name',
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: 'Leave For',
      selector: (row) => row?.count,
    },
    {
      name: 'Start Date',
      selector: (row) => row?.startDate.split('T')[0],
      sortable: true,
    },
    {
      name: 'End Date',
      selector: (row) => row?.endDate.split('T')[0],
      sortable: true,
    },
    {
      name: 'Leave Type',
      selector: (row) => row?.type,
      sortable: true,
    },
  ];

  // setup filter for search
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredPendingLeave = pendingLeave.filter(
    (item) =>
      (item.employeeInfo?.name &&
        item.employeeInfo?.name
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item.type &&
        item.type.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.title &&
        item.title.toLowerCase().includes(filterText.toLowerCase()))
  );
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  // expandable row configuration
  const ExpandedComponent = ({ data }) => (
    <Card key={data._id} pendingLeave={data} />
  );
  return (
    <>
      <h2 className='mainTitle'>Leave Data of {props.empName}</h2>
      {pendingLeave.length > 0 && (
        <div className='pendingLeave'>
          <h2>Pending Leave applications</h2>
          <DataTable
            columns={columns}
            data={filteredPendingLeave}
            persistTableHead
            highlightOnHover
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
          />
        </div>
      )}

      {approvedLeave.length > 0 && (
        <ApprovedLeave approvedLeave={approvedLeave} />
      )}

      {rejectedLeave.length > 0 && (
        <RejectedLeave rejectedLeave={rejectedLeave} />
      )}
    </>
  );
}

export default EmpLeaveData;
