import { useEffect, useState } from 'react';

import useFetch from '../hooks/useFetch';
import HolidayLeaveManagement from '../pages/HolidayLeaveManagement';
import EmpLeaveData from './EmpLeaveData';
import { useAuth } from '../context/AuthContext';
import { ApiCall } from '../api';

const EmployeeHomeView = ({ empId, empName }) => {
  const { tokenValue } = useAuth();
  const [totalApplication, setTotalApplication] = useState([]);
  const { data: employeeLeaveData } = useFetch(`/leave/emp/${empId}`);
  const url = `holiday/leave/applications/emp/${empId}`;

  useEffect(() => {
    const fetchApplicationData = async () => {
      const result = await ApiCall(url, 'get', tokenValue);
      setTotalApplication(result.data.data?.totalApplication);
    };
    fetchApplicationData();
  }, [tokenValue, url]);

  const empPendingLeaveData = employeeLeaveData?.data?.filter(
    (data) => data?.currentStatus?.toLowerCase() === 'pending'
  );
  const empApprovedLeaveData = employeeLeaveData?.data?.filter(
    (data) => data?.currentStatus?.toLowerCase() === 'approved'
  );
  const empRejectLeaveData = employeeLeaveData?.data?.filter(
    (data) => data?.currentStatus?.toLowerCase() === 'reject'
  );
  return (
    <>
      {(empPendingLeaveData?.length > 0 ||
        empApprovedLeaveData?.length > 0 ||
        empRejectLeaveData?.length > 0) && (
        <>
          <EmpLeaveData
            empName={empName}
            pendingLeave={empPendingLeaveData}
            approvedLeave={empApprovedLeaveData}
            rejectedLeave={empRejectLeaveData}
          />
        </>
      )}
      {totalApplication > 0? (
        <HolidayLeaveManagement title = "Holiday Leave Adjustment Request" employeeId={empId} dashboardView={true}/>
      ): ""}
      
    </>
  );
};

export default EmployeeHomeView;
