import React, { useState } from 'react';
import BackButton from '../components/BackButton';
import { apiClient } from '../api';
import { useAuth } from '../context/AuthContext';
import TextInput from '../components/forms/TextInput';
import TextAreaInput from '../components/forms/TextAreaInput';
import { useForm } from 'react-hook-form';
// Modal files include
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';

function AddTicket() {
  const { tokenValue, currentUser } = useAuth();
  const navigate = useNavigate();
  const [msg, setMsg] = useState('');
  const [submitting, setSubmitting] = useState(false);
  // modal configuration data
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    navigate(-1);
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setSubmitting(true);
    apiClient
      .post('/ticket/create', data, {
        headers: {
          authorization: `Bearer ${tokenValue}`,
        },
      })
      .then((res) => {
        setMsg(res.data.msg);
        if (res?.data?.msg === 'added successfully') {
          reset();
          setSubmitting(false);
        }
        onOpenModal();
      })
      .catch((err) => {
        setMsg(err);
        setSubmitting(false);
        onOpenModal();
      });
  };
  return (
    <main>
      <div className="container">
        {/* {loading && <h6>Loading emp data....</h6>}
			{error && <h6>{error}</h6>} */}
        <>
          <h1>Add a new ticket for your request</h1>
          <BackButton />
          <div className="book__form">
            <form
              method="post"
              className="form leaveForm"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                type="hidden"
                name="employeeId"
                defaultValue={currentUser._id}
                {...register('employeeId')}
              />
              <input
                type="hidden"
                name="supervisorId"
                defaultValue={currentUser.supervisorId}
                {...register('supervisorId')}
              />
              <TextInput
                label="Ticket Title"
                name="title"
                id="title"
                type="text"
                register={register}
                rules={{
                  required: '*field is required',
                }}
                errors={errors}
              />

              <TextAreaInput
                label="Ticket Description"
                name="description"
                id="description"
                register={register}
              />

              <div className="form-item">
                <input
                  className="btn btn-primary"
                  type="submit"
                  name="submit"
                  value="Create Ticket"
                  disabled={submitting}
                />
              </div>
            </form>
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              classNames={{
                modal: 'customModal',
              }}
              animationDuration={800}
            >
              <p>{msg}</p>
            </Modal>
          </div>
        </>
      </div>
    </main>
  );
}

export default AddTicket;
