import React from 'react';
import { Link } from 'react-router-dom';

const AdminSummary = (props) => {
  return (
    <>
      <main>
        <h1>Leave Management System | Brain Craft LTD.</h1>
        <div className="infoSummary">
          <div className="gridRow">
            <div className="card dash-widget shadow-sm">
              <div className="card-body">
                <div className="card-icon bg-primary">
                  <i className="fas fa-users" aria-hidden="true"></i>
                </div>
                <Link to={`/employee-management`} className="btn">
                  <div className="card-right">
                    <h4 className="card-title">Employees</h4>
                    <p className="card-text">{props.activeUser}</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="card dash-widget shadow-sm">
              <div className="card-body">
                <div className="card-icon bg-primary">
                  <i className="fas fa-user-friends" aria-hidden="true"></i>
                </div>
                <Link to={`/leave-management`} className="btn">
                  <div className="card-right">
                    <h4 className="card-title">Leave Application</h4>
                    <p className="card-text">{props.pendingLeave}</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="card dash-widget shadow-sm">
              <div className="card-body">
                <div className="card-icon bg-primary">
                  <i className="fas fa-calendar-alt" aria-hidden="true"></i>
                </div>
                <Link to={`/leave-management`} className="btn">
                  <div className="card-right">
                    <h4 className="card-title">On Leave Today</h4>
                    <p className="card-text">{props.todaysLeave}</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AdminSummary;
