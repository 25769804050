import React from 'react';

function CardLeaveInfo(props) {
  return (
    <>
      {props?.employeeName && <h4>{props?.employeeName}</h4>}
      {props?.employeePhone && <p>Phone: {props?.employeePhone}</p>}
      {props?.consumedLeave ? (
        <p>Total Consumed Leave: {props?.consumedLeave}</p>
      ) : (
        ''
      )}
      {props?.availableLeave ? (
        <p>Total Available Leave:{' ' + props?.availableLeave}</p>
      ) : (
        ''
      )}
      {props?.availableSickLeave ? (
        <p>Remaining Sick Leave: {' ' + props?.availableSickLeave}</p>
      ) : (
        ''
      )}
      {props?.availableCasualLeave ? (
        <p>Remaining Casual Leave: {' ' + props?.availableCasualLeave}</p>
      ) : (
        ''
      )}
      {props?.availableFamilyLeave ? (
        <p>Remaining Family Leave: {' ' + props?.availableFamilyLeave}</p>
      ) : (
        ''
      )}
      {props?.leaveCount ? (
        <p>
          Leave For:
          {' ' + props?.leaveCount + (props?.leaveCount > 1 ? ' Days' : ' Day')}
        </p>
      ) : (
        ''
      )}
      {props?.leaveStartDate && <p>Start Date:{' ' + props?.leaveStartDate}</p>}
      {props?.leaveEndDate && <p>End Date:{' ' + props?.leaveEndDate}</p>}
      {props?.leavePenalty ? (
        <p>Leave Penalty: {' ' + props.leavePenalty}</p>
      ) : (
        ''
      )}
    </>
  );
}

export default CardLeaveInfo;
