import { useEffect, useState } from 'react';
import { apiUrl } from '../api';
import { useAuth } from '../context/AuthContext';

import BackButton from '../components/BackButton';
import DataTable from '../components/DataTableBase';

import { useForm } from 'react-hook-form';
import Modal from 'react-responsive-modal';

const AddHoliday = () => {
  const { currentUser, tokenValue } = useAuth();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [defaultHolidayName, setDefaultHolidayName] = useState('');
  const [defaultHolidayDate, setDefaultHolidayDate] = useState('');
  const [id, setId] = useState('');

  const monthArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      holidayName: defaultHolidayName,
      holidayDate: defaultHolidayDate,
    },
  });

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${apiUrl}/holiday`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${tokenValue}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          setMessage(error);
          onOpenModal();
        });
    };
    fetchData();
  }, [tokenValue, open]);

  const handleDeleteRow = (row) => {
    fetch(`${apiUrl}/holiday/${row._id}`, {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${tokenValue}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setMessage(data.message || data.error);
        onOpenModal();
      })
      .catch((error) => {
        setMessage(error);
        onOpenModal();
      });
  };

  const handleEditRow = (row) => {
    const currentYear = new Date().getFullYear();
    const validDateString = `${currentYear}-${
      row?.holidayDate?.split('/')[1]
    }-${row?.holidayDate?.split('/')[0]}`;

    setDefaultHolidayName(row?.holidayName);
    setDefaultHolidayDate(validDateString);
    setId(row?._id);
    setValue('holidayName', row?.holidayName);
    setValue('holidayDate', validDateString);
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    const dateToString = data?.holidayDate?.split('-');
    data.holidayDate = `${dateToString[2]}/${dateToString[1]}`;

    if (id) {
      fetch(`${apiUrl}/holiday/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${tokenValue}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setMessage(data.message || data.error);
          if (data.code === 200) {
            setId('');
            setDefaultHolidayName('');
            setDefaultHolidayDate('');
            setSubmitting(false);
            reset();
          }
          onOpenModal();
        })
        .catch((error) => {
          setMessage(error);
          setSubmitting(false);
          onOpenModal();
        });
    } else {
      fetch(`${apiUrl}/holiday/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${tokenValue}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setMessage(data.message || data.error);
          if (data.code === 200) {
            reset();
            setSubmitting(false);
          }
          onOpenModal();
        })
        .catch((error) => {
          setMessage(error);
          setSubmitting(false);
          onOpenModal();
        });
    }
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: '70px', // override the row height
      },
    },
    table: {
      style: {
        border: '1px solid #EEEEEE', // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: '18px',
        fontWeight: '700',
      },
    },
    cells: {
      style: {
        fontSize: '16px',
        'div:first-child': {
          whiteSpace: 'unset!important',
        },
        img: {
          width: '70px',
          'object-fit': 'cover',
        },
      },
    },
    pagination: {
      style: {
        fontSize: '16px',
      },
    },
  };

  const adminColumns = [
    {
      name: 'Holiday Name',
      selector: (row) => row?.holidayName,
      sortable: true,
    },
    {
      name: 'Holiday Date',
      selector: (row) => {
        const date = Number(row?.holidayDate.split('/')[0]);
        const month = Number(row.holidayDate.split('/')[1]) - 1;

        const nameOfMonth = monthArray[month];
        const returnedDate = `${nameOfMonth} ${date}`;

        return returnedDate;
      },
    },
    {
      name: 'Week Day',
      selector: (row) => {
        const currentYear = new Date().getFullYear();
        const validDateString = `${currentYear}-${
          row?.holidayDate?.split('/')[1]
        }-${row?.holidayDate?.split('/')[0]}`;

        const date = new Date(validDateString);
        const day = date.getDay();

        return weekDays[day];
      },
    },
    {
      name: 'Edit',
      selector: (row) => (
        <button onClick={() => handleEditRow(row)} className='btn backBtn'>
          Edit
        </button>
      ),
    },
    {
      name: 'Delete',
      selector: (row) => (
        <button onClick={() => handleDeleteRow(row)} className='btn backBtn'>
          Delete
        </button>
      ),
    },
  ];
  const userColumns = [
    {
      name: 'Holiday Name',
      selector: (row) => row?.holidayName,
      sortable: true,
    },
    {
      name: 'Holiday Date',
      selector: (row) => {
        const date = Number(row?.holidayDate.split('/')[0]);
        const month = Number(row.holidayDate.split('/')[1]) - 1;

        const nameOfMonth = monthArray[month];
        const returnedDate = `${nameOfMonth} ${date}`;

        return returnedDate;
      },
    },
    {
      name: 'Week Day',
      selector: (row) => {
        const currentYear = new Date().getFullYear();
        const validDateString = `${currentYear}-${
          row?.holidayDate?.split('/')[1]
        }-${row?.holidayDate?.split('/')[0]}`;

        const date = new Date(validDateString);
        const day = date.getDay();

        return weekDays[day];
      },
    },
  ];

  return (
    <main>
      <div className='container'>
        <h1>
          {currentUser?.userRole.toLowerCase() === 'admin'
            ? 'Yearly Holiday Management'
            : 'Yearly Holidays'}
        </h1>
        <BackButton />

        {currentUser?.userRole.toLowerCase() === 'admin' ? (
          <div className='book__form'>
            <form className='form leaveForm' onSubmit={handleSubmit(onSubmit)}>
              <div className='form-item'>
                <label htmlFor='holidayName'>Holiday Name</label>
                <input
                  id='holidayName'
                  className='field_sm'
                  type='text'
                  defaultValue={defaultHolidayName}
                  {...register('holidayName', {
                    required: '*holiday name is required',
                  })}
                />
                {errors?.holidayName && (
                  <span className='error'>{errors?.holidayName?.message}</span>
                )}
              </div>

              <div className='form-item'>
                <label htmlFor='holidayDate'>Holiday Date</label>
                <input
                  id='holidayDate'
                  className='field_sm'
                  type='date'
                  defaultValue={defaultHolidayDate}
                  {...register('holidayDate', {
                    required: '*holiday date is required',
                  })}
                />
                {errors?.holidayDate && (
                  <span className='error'>{errors?.holidayDate?.message}</span>
                )}
              </div>

              <div className='form-item'>
                <input
                  className='btn btn-primary'
                  type='submit'
                  name='submit'
                  value={id ? 'Update' : 'Save'}
                  disabled={submitting}
                />
              </div>
            </form>
          </div>
        ) : (
          ''
        )}

        <div
          className={
            currentUser?.userRole.toLowerCase() === 'admin' ? '' : 'mt-30'
          }
        >
          <DataTable
            columns={
              currentUser?.userRole.toLowerCase() === 'admin'
                ? adminColumns
                : userColumns
            }
            data={data?.data}
            customStyles={customStyles}
            persistTableHead
            highlightOnHover
          />
        </div>

        <Modal
          open={open}
          onClose={onCloseModal}
          center
          classNames={{
            modal: 'customModal',
          }}
          animationDuration={800}
        >
          <p>{message}</p>
        </Modal>
      </div>
    </main>
  );
};

export default AddHoliday;
