import React, { useMemo, useState } from 'react';
import Card from './Card';
// data table import
import DataTable from '../components/DataTableBase';
import { Link } from 'react-router-dom';
import { FilterComponent } from './FilterComponent';

const PendingLeave = (props) => {
  const pendingLeave = props.pendingLeaveData;
  const columns = [
    {
      name: 'Name',
      selector: (row) => row?.employeeInfo?.name,
      sortable: true,
    },
    {
      name: 'Leave For',
      selector: (row) => row?.count,
    },
    {
      name: 'Start Date',
      selector: (row) => row?.startDate.split('T')[0],
      sortable: true,
    },
    {
      name: 'End Date',
      selector: (row) => row?.endDate.split('T')[0],
      sortable: true,
    },
    {
      name: 'Leave Type',
      selector: (row) => row?.type,
      sortable: true,
    },
    {
      name: 'Edit',
      selector: (row) => (
        <Link to={`/leave-review/${row._id}`} state={row} className="btn">
          Review
        </Link>
      ),
    },
  ];

  // setup filter for search
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredPendingLeave = pendingLeave.filter(
    (item) =>
      (item.employeeInfo?.name &&
        item.employeeInfo?.name
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item.type &&
        item.type.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.title &&
        item.title.toLowerCase().includes(filterText.toLowerCase()))
  );
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  // expandable row configuration
  const ExpandedComponent = ({ data }) => (
    <Card
      key={data._id}
      pendingLeave={data}
      leaveLink={`/leave-review/${data._id}`}
    />
  );
  if (pendingLeave.length === 0) {
    return <h2 className="mt-30">No Pending Leave applications</h2>;
  }
  return (
    <>
      {pendingLeave.length > 0 && (
        <div className="pendingLeave">
          <h2>Pending Leave applications</h2>
          <DataTable
            columns={columns}
            data={filteredPendingLeave}
            persistTableHead
            highlightOnHover
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
          />
        </div>
      )}
    </>
  );
};

export default PendingLeave;
