import React from 'react';
import ServerErrorImg from '../resources/server_error.png';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  handleReload() {
    window?.location?.reload();
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI

      //   return this.props.fallback;

      return (
        <div className='error-boundary'>
          <img src={ServerErrorImg} alt='Server Side Error' />
          <h1>{this.props.fallback}</h1>
          <h2>It's not you. It's us.</h2>
          <button onClick={this.handleReload}>Try Again</button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
